import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SETTINGS } from "../../GraphQL/Queries";
import PropTypes from "prop-types";
import { indexedDbGetById, indexedDbUpdate } from "../../utils";

const VersionControl = (props) => {
  const indexedDbStore = "persistent-data";
  const indexedDbKey = "version";
  const enabled = true;
  const timeout = 30 * 60 * 1000; // First value is minutes
  const [hotUpdate, setHotUpdate] = useState(false);
  const [version, setVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [getVersion, { data: versionData, refetch: refetchVersion }] =
    useLazyQuery(GET_SETTINGS, {
      variables: {
        key: "version",
      },
    });

  const checkVersion = useCallback(() => {
    refetchVersion().then(() => {
      // if (versionData && versionData.version) {
      //     updateLatestVersion(versionData.version);
      // }
    });
  }, [refetchVersion]);

  const updateVersionData = useCallback(() => {
    if (versionData) {
      let versionObject = JSON.parse('{"major": 0, "minor": 0, "release": 0}');
      let combinedVersion = "0.0.0";
      if (versionData.settings) {
        versionObject = JSON.parse(versionData.settings);
      }

      if (
        versionObject.major > -1 &&
        versionObject.minor > -1 &&
        versionObject.release > -1
      ) {
        combinedVersion =
          versionObject.major +
          "." +
          versionObject.minor +
          "." +
          versionObject.release;
      }

      setLatestVersion(combinedVersion);
    }
  }, [versionData]);

  useEffect(() => {
    if (enabled) {
      checkLocalVersion();
      checkVersion();
      setInterval(checkVersion, timeout);
    }
  }, [enabled, timeout, checkVersion]);

  useEffect(() => {
    getVersion().then(() => {
      updateVersionData();
    });
  }, [getVersion, updateVersionData]);

  useEffect(() => {
    if (version && latestVersion && version !== latestVersion) {
      setHotUpdate(true);
    }
  }, [version, latestVersion]);

  useEffect(() => {
    if (versionData) {
      updateVersionData();
    }
  }, [versionData, updateVersionData]);

  const manageUpdate = useCallback(() => {
    if (latestVersion) {
      indexedDbUpdate(indexedDbStore, latestVersion, indexedDbKey)
        .then(() => {
          setTimeout(() => {
            // window.location.reload(); // It is loading from cache.
            window.location.replace(window.location.href);
          }, 10000);
        })
        .catch(() => {});
    }
  }, [indexedDbStore, latestVersion, indexedDbKey]);

  // const manageUpdate = () => {
  //   if (latestVersion) {
  //     update(indexedDbStore, latestVersion, indexedDbKey)
  //       .then(() => {
  //         window.location.reload();
  //       })
  //       .catch(() => {
  //       });
  //   }
  // };

  // const closeUpdateMessage = () => {
  //   updateHotUpdate(!hotUpdate);
  // };

  const checkLocalVersion = () => {
    indexedDbGetById(indexedDbStore, indexedDbKey)
      .then((version) => {
        if (version) {
          setVersion(version);
        } else {
          setHotUpdate(true);
        }
      })
      .catch(() => {
        setHotUpdate(true);
      });
  };

  useEffect(() => {
    if (hotUpdate) {
      manageUpdate();
    }
  }, [hotUpdate, manageUpdate]);

  // const renderHotUpdate = (update, close) =>
  //   hotUpdate && (
  //     <div className={"sticky-message-container"}>
  //       <div className={"sticky-message"}>
  //         A newer application version is available.
  //         <button className={"btn btn-link btn-sm"} onClick={update}>
  //           UPDATE NOW
  //         </button>
  //         <button
  //           className={"btn btn-danger btn-sm close-btn"}
  //           title={"Close"}
  //           onClick={close}
  //         >
  //           X
  //         </button>
  //       </div>
  //     </div>
  //   );

  return (
    <>
      {props.children}
      {/* enabled && ({renderHotUpdate(manageUpdate, closeUpdateMessage)}) */}
    </>
  );
};

export default VersionControl;

VersionControl.propTypes = {
  children: PropTypes.node,
};
