import React, { useContext, useEffect, useState } from "react";
import { useLang, useTextDomainContext, useUser } from "../../providers";
import { useClimateData } from "../../../hooks";
import { FilterBar1, LineChartBox, TableView1 } from "../../components";
import moment from "moment";
import {
  formatDateForAPI,
  formatDateYear,
  getPreviousYearDate,
  segmentList,
  toBenchmarkDataClimate,
  toLocaleNumber,
  urlFriendlyString,
} from "../../../utils";
import { useTheme } from "@mui/material";
import { isEqual } from "lodash";

export const Carbon = () => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const { user } = useUser();
  const segments = segmentList();
  let { currentTeam: customer, currentSubsidiary: group } = user || {};
  const locale = useLang();
  const theme = useTheme();
  if (!customer) {
    customer = "all";
  }
  if (!group) {
    group = "All";
  }

  //region React Hooks (useState)
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("all");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [segment, setSegment] = useState("year");
  const [shortSegment, setShortSegment] = useState("m");
  const [chartData, setChartData] = useState([]);
  const [fileNameForExport, setFileNameForExport] = useState(
    "carbon-emissions-export",
  );
  const [tableData, setTableData] = useState([]);
  //endregion  React Hooks (useState)

  //region Data
  // const filters = {
  //   customer,
  //   group,
  //   address: building,
  //   meteringPointId: meter,
  //   meterType: energyType,
  //   fromDate: formatDateForAPI(fromDate),
  //   toDate: formatDateForAPI(toDate),
  //   resolution: shortSegment,
  //   fileType: "csv",
  // };
  // const { data: metersData, loading } = useClimateData(filters);

  const thisYearFilter = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: formatDateForAPI(fromDate),
    toDate: formatDateForAPI(toDate),
    resolution: shortSegment,
  };

  const previousYearFilter = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: getPreviousYearDate(thisYearFilter.fromDate),
    toDate: getPreviousYearDate(thisYearFilter.toDate),
    resolution: shortSegment,
  };

  const {
    data: metersDataThisYear,
    total: totalThisYear,
    loadingThisYear,
  } = useClimateData(thisYearFilter);

  const {
    data: metersDataPreviousYear,
    total: totalPreviousYear,
    loadingPreviousYear,
  } = useClimateData(previousYearFilter);

  const thisYear = formatDateYear(thisYearFilter.fromDate);
  const previousYear = formatDateYear(previousYearFilter.fromDate);

  //endregion Data

  //region React Hooks (useEffect)

  useEffect(() => {
    const calculatedTableData = toBenchmarkDataClimate(
      shortSegment,
      [
        {
          data: metersDataThisYear,
          total: totalThisYear,
        },
        {
          data: metersDataPreviousYear,
          total: totalPreviousYear,
        },
      ],
      gettext,
      true,
    );

    const calculatedChartData = toBenchmarkDataClimate(
      shortSegment,
      [
        {
          data: metersDataThisYear,
          total: totalThisYear,
        },
        {
          data: metersDataPreviousYear,
          total: totalPreviousYear,
        },
      ],
      false,
    );

    if (!isEqual(tableData, calculatedTableData)) {
      setTableData(calculatedTableData);
    }

    if (!isEqual(chartData, calculatedChartData)) {
      setChartData(calculatedChartData);
    }

    // console.log(calculatedTableData);
  }, [
    chartData,
    gettext,
    metersDataPreviousYear,
    metersDataThisYear,
    shortSegment,
    tableData,
    totalPreviousYear,
    totalThisYear,
  ]);

  useEffect(() => {
    setFileNameForExport(
      urlFriendlyString(
        "carbon-emissions-" +
          building +
          "-" +
          meter +
          "-" +
          energyType +
          "-" +
          segment +
          "-" +
          fromDate +
          "-" +
          toDate,
      ),
    );
  }, [building, energyType, fromDate, meter, segment, toDate]);

  // useEffect(() => {
  //   if (metersData) {
  //     const chartDateTemp = meterApiDataToBarChartData(
  //       metersData,
  //       shortSegment,
  //     );
  //     if (!isEqual(chartDateTemp, chartData)) {
  //       setChartData(meterApiDataToBarChartData(metersData, shortSegment));
  //     }
  //   }
  // }, [chartData, metersData, shortSegment]);

  //endregion React Hooks (useEffect)

  //region For Table

  const columnGroupingModel = [
    // {
    //   groupId: "ts",
    //   children: [{ field: "ts" }],
    // },
    {
      groupId: gettext("Consumption (kWh)"),
      headerAlign: "center",
      headerClassName: "borderLeft",
      children: [
        { field: `consumptionV_${previousYear}` },
        { field: `consumptionV_${thisYear}` },
      ],
    },
    {
      groupId: gettext("Conversion Rate (g CO₂e/kWh)"),
      headerAlign: "center",
      headerClassName: "borderLeft",
      children: [
        { field: `conversionRate_${previousYear}` },
        { field: `conversionRate_${thisYear}` },
      ],
    },
    {
      groupId: gettext("Kg of CO₂e"),
      headerAlign: "center",
      headerClassName: "borderLeft",
      children: [
        { field: `co2_${previousYear}` },
        { field: `co2_${thisYear}` },
      ],
    },
  ];

  const columns = [
    {
      field: "ts",
      headerName: gettext("Date"),
      flex: 1,
      // type: "date",
      headerAlign: "center",
      // valueGetter: (value) => {
      //   let returnValue = "";
      //   if (value) {
      //     if (value === "Summary") {
      //       returnValue = value;
      //     } else {
      //       returnValue = new Date(value);
      //     }
      //   }
      //   return returnValue;
      // },
      // valueFormatter: (value) => {
      //   let returnValue = "";
      //   if (value) {
      //     if (value === "Summary") {
      //       returnValue = value;
      //     } else {
      //       // returnValue = capitalizeFirstLetter(moment(value).format(segments[segment].dateFormat));
      //       returnValue = formatDateBySegment(value, segment);
      //     }
      //   }
      //   return returnValue;
      // },
    },
    {
      field: `consumptionV_${previousYear}`,
      headerName: previousYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      headerClassName: "borderLeft",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    },
    {
      field: `consumptionV_${thisYear}`,
      headerName: thisYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    },
    {
      field: `conversionRate_${previousYear}`,
      headerName: previousYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      headerClassName: "borderLeft",
      valueFormatter: (value) => value && value.toFixed(2),
    },
    {
      field: `conversionRate_${thisYear}`,
      headerName: thisYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      valueFormatter: (value) => value && value.toFixed(2),
    },
    {
      field: `co2_${previousYear}`,
      headerName: previousYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      headerClassName: "borderLeft",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: `co2_${thisYear}`,
      headerName: thisYear,
      flex: 1,
      type: "number",
      headerAlign: "center",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    // {
    //   field: "consumptionCost",
    //   headerName: gettext("Cost (DKK)"),
    //   flex: 1,
    //   type: "number"
    // }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50,
      },
    },
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };
  //endregion For Table

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }

    if (selectedValues?.segment) {
      setSegment(selectedValues.segment);
      setShortSegment(segments[selectedValues.segment].shortSegment);
      if (
        selectedValues.segment === "day" &&
        moment(selectedValues?.date?.startDate).format("YYYY-MM-DD") !==
          moment(selectedValues?.date?.endDate).format("YYYY-MM-DD")
      ) {
        setSegment("month");
        setShortSegment(segments["month"].shortSegment);
      }
    }
  };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        showBuilding
        showMeter
        showEnergyType
        // showDateMultiple
        showDateSingle
        showBuildingAll
        showMeterAll
        // showEnergyTypeAll
      />
      <br />

      {/*<BarChartBox*/}
      {/*  loading={loadingThisYear || loadingPreviousYear}*/}
      {/*  title={gettext("Kg of CO₂e")}*/}
      {/*  chartId={"co2"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [*/}
      {/*      {*/}
      {/*        dataKey: "co2",*/}
      {/*        label: gettext("Consumption"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("Kilogram"),*/}
      {/*      },*/}
      {/*    ],*/}
      {/*  }}*/}
      {/*/>*/}

      <LineChartBox
        loading={loadingThisYear || loadingPreviousYear}
        title={gettext("Kg of CO₂e")}
        chartId={"co2"}
        data={{
          dataset: chartData,
          series: [
            {
              dataKey: `co2_${previousYear}`,
              label: previousYear.toString(),
              color: theme.palette.chart.year2,
              unit: gettext("Kilogram"),
            },
            {
              dataKey: `co2_${thisYear}`,
              label: thisYear.toString(),
              color: theme.palette.chart.year1,
              unit: gettext("Kilogram"),
            },
          ],
        }}
      />
      <br />

      {/*<BarChartBox*/}
      {/*  loading={loadingThisYear || loadingPreviousYear}*/}
      {/*  title={gettext("Conversion rate in g CO₂e/kWh")}*/}
      {/*  chartId={"conversion"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [*/}
      {/*      {*/}
      {/*        dataKey: "conversionRate",*/}
      {/*        label: gettext("Consumption"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("g CO₂/kW"),*/}
      {/*      },*/}
      {/*    ],*/}
      {/*  }}*/}
      {/*/>*/}

      <LineChartBox
        loading={loadingThisYear || loadingPreviousYear}
        title={gettext("Conversion rate in g CO₂e/kWh")}
        chartId={"conversion"}
        data={{
          dataset: chartData,
          series: [
            {
              dataKey: `conversionRate_${previousYear}`,
              label: previousYear.toString(),
              color: theme.palette.chart.year2,
              unit: gettext("g CO₂/kW"),
            },
            {
              dataKey: `conversionRate_${thisYear}`,
              label: thisYear.toString(),
              color: theme.palette.chart.year1,
              unit: gettext("g CO₂/kW"),
            },
          ],
        }}
      />
      <br />

      {/*<BarChartBox*/}
      {/*  loading={loadingThisYear || loadingPreviousYear}*/}
      {/*  title={gettext("Consumption measured in kWh")}*/}
      {/*  chartId={"consumption"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [*/}
      {/*      {*/}
      {/*        dataKey: "consumptionV",*/}
      {/*        label: gettext("Consumption"),*/}
      {/*        color: theme.palette.chart.electricity,*/}
      {/*        unit: gettext("kWh"),*/}
      {/*      },*/}
      {/*    ],*/}
      {/*  }}*/}
      {/*/>*/}

      <LineChartBox
        loading={loadingThisYear || loadingPreviousYear}
        title={gettext("Consumption measured in kWh")}
        chartId={"consumption"}
        data={{
          dataset: chartData,
          series: [
            {
              dataKey: `consumptionV_${previousYear}`,
              label: previousYear.toString(),
              color: theme.palette.chart.year2,
              unit: gettext("kWh"),
            },
            {
              dataKey: `consumptionV_${thisYear}`,
              label: thisYear.toString(),
              color: theme.palette.chart.year1,
              unit: gettext("kWh"),
            },
          ],
        }}
      />

      <br />

      <TableView1
        title={gettext("Electricity consumption and CO₂e")}
        rows={tableData}
        columns={columns}
        loading={loadingThisYear || loadingPreviousYear}
        initialState={initialState}
        showGridToolbar
        showGridToolbarExport
        fileNameForExport={fileNameForExport}
        disableColumnSorting
        disableColumnSelector
        columnGroupingModel={columnGroupingModel}
      />
    </>
  );
};
