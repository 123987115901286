/* global process */
import React from "react";
import {Error500} from "../pages";
import {sendSlackMessage} from "../../utils";
import PropTypes from "prop-types";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  async componentDidMount() {
    // Override the default console.error behavior
    if (process.env.REACT_APP_NODE_ENV === "production") {
      // eslint-disable-next-line no-console
      const originalConsoleError = console.error;
      // eslint-disable-next-line no-console
      console.error = function (...args) {
        const message = args.join(' ').split('\n').join('\\n');

        const slackErrorData = {
          channel: "error",
          type: "error",
          heading: window.location.href,
          detail: message,
          fields: []
        };
        sendSlackMessage(slackErrorData).then();

        originalConsoleError.apply(console, args);
      };
    }
  }

  async componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });

    this.setState({
      error: error,
      info: info
    });

    if (process.env.REACT_APP_NODE_ENV === "production") {
      const slackErrorData = {
        channel: "error",
        type: "error",
        heading: window.location.href,
        detail: error.toString().split('\n').join('\\n'),
        fields: []
      };
      sendSlackMessage(slackErrorData).then();
    }
  }

  render() {
    if (this.state.hasError) {
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        return (
          <>
            <h1>Something went wrong. {process.env.REACT_APP_NODE_ENV}</h1>
            <pre>{this.state.error.stack}</pre>
            <hr/>
            <h2>Full Stack</h2>
            <pre>{this.state.info.componentStack}</pre>
          </>
        );
      } else {
        return <Error500/>;
      }
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

// export ErrorBoundary;
