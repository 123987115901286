import { Grid } from "@mui/material";
import { useTextDomainContext } from "../../../providers";
import React, { useContext, useEffect, useState } from "react";
import InfoBar from "./components/InfoBar";
import PageIndicator from "./components/PageIndicator";
import DatahubBuildings from "./DatahubBuildings";
import DatahubCustomers from "./DatahubCustomers";
import DatahubMeterDetails from "./DatahubMeterDetails";
import DatahubMeters from "./DatahubMeters";
import { convertDateTime, useSelectedItem, useView } from "./hooks";

const crumbs = [
  {
    label: "DataHub",
    value: 0,
  },
  {
    label: "DataHub Companies",
    value: "Customers",
  },
  {
    label: "Building details page",
    value: "Buildings",
  },
  {
    label: "Meter List",
    value: "Meters",
  },
  {
    label: "Meter details",
    value: "Meter Details",
  },
];

const Datahub = () => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const [selectedItem, setSelectedItem] = useSelectedItem(null);
  const [view, setView] = useView("Customers");
  const [customerName, setCustomerName] = useState("");
  const [buildingAddress, setBuildingAddress] = useState();
  const [cvr, setCvr] = useState();
  const [accessPeriod, setAccessPeriod] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState();
  const [meterIdList, setMeterIdList] = useState([]);
  const [meteringPointId, setMeteringPointId] = useState("");

  useEffect(() => {
    if (view === "Buildings" && selectedItem) {
      //for params to datahubcomponnet
      setId(selectedItem?.id);
      //for infobar
      setCustomerName(selectedItem?.customerName);
      setCvr(selectedItem?.customerCvr);
      setAccessPeriod(
        convertDateTime(selectedItem?.validFrom, "dateTime") +
          " to " +
          convertDateTime(selectedItem?.validTo, "dateTime"),
      );
      setStatus(selectedItem?.status);
    } else if (view === "Meters" && selectedItem) {
      //for params to datahubcomponnet
      setMeterIdList(selectedItem.meterIdList);
      //for infobar
      setBuildingAddress(selectedItem?.address);
    } else if (view === "Meter Detail" && selectedItem) {
      //for params to datahubcomponnet
      setMeteringPointId(selectedItem.meteringPointId);
    }
  }, [view, selectedItem]);

  return (
    <Grid Container display="flex" flexDirection="column" rowGap={3}>
      <Grid item>
        <PageIndicator
          view={view}
          setView={setView}
          gettext={gettext}
          crumbs={crumbs}
        />
      </Grid>
      {view !== "Customers" && view !== "Meters Detail" && (
        <Grid item>
          <InfoBar
            view={view}
            customerName={customerName}
            buildingAddress={buildingAddress}
            cvr={cvr}
            accessPeriod={accessPeriod}
            status={status}
            gettext={gettext}
          />
        </Grid>
      )}
      {view === "Customers" ? (
        <DatahubCustomers
          view={view}
          setView={setView}
          setSelectedItem={setSelectedItem}
          gettext={gettext}
        />
      ) : view === "Buildings" ? (
        <DatahubBuildings
          view={view}
          setView={setView}
          id={id}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          gettext={gettext}
        />
      ) : view === "Meters" ? (
        <DatahubMeters
          view={view}
          setView={setView}
          meterIdList={meterIdList}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          gettext={gettext}
        />
      ) : view === "Meter Detail" ? (
        <DatahubMeterDetails
          view={view}
          setView={setView}
          meteringPointId={meteringPointId}
          gettext={gettext}
        />
      ) : null}
    </Grid>
  );
};
export default Datahub;
