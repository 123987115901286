import React, { useEffect, useState } from "react";
import detectBrowserLanguage from "detect-browser-language";
import { useUser } from "../UserProvider";
import { useLocale } from "../LocaleProvider";
import { useAuth } from "../AuthProvider";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { da } from "date-fns/locale/da";
import { de } from "date-fns/locale/de";
import en from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { fr } from "date-fns/locale/fr";
import { nb } from "date-fns/locale/nb";
import { nl } from "date-fns/locale/nl";
import { sv } from "date-fns/locale/sv";
import {
  buildTextDomain,
  TextDomainContext,
} from "@absolutesight/react-gettext";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/da";
import PropTypes from "prop-types";

registerLocale("da", da);
registerLocale("de", de);
registerLocale("en", en);
registerLocale("es", es);
registerLocale("fr", fr);
registerLocale("nb", nb);
registerLocale("nl", nl);
registerLocale("no", nb);
registerLocale("sv", sv);

// import { buildTextdomain } from "../../../modules/src";
// import { IntlProvider } from "react-intl";

// import daMessages from "_metronic/i18n/messages/da";
// import deMessages from "_metronic/i18n/messages/de";
// import enMessages from "_metronic/i18n/messages/en";
// import esMessages from "_metronic/i18n/messages/es";
// import frMessages from "_metronic/i18n/messages/fr";
// import jaMessages from "_metronic/i18n/messages/ja";
// import zhMessages from "_metronic/i18n/messages/zh";
// import noMessages from "_metronic/i18n/messages/no";
// import svMessages from "_metronic/i18n/messages/sv";
// const allMessages = {
//   da: daMessages,
//   de: deMessages,
//   en: enMessages,
//   es: esMessages,
//   fr: frMessages,
//   ja: jaMessages,
//   zh: zhMessages,
//   no: noMessages,
//   sv: svMessages
// };

export let LANG = "en";

/**
 *
 */
export function useLang() {
  const { user } = useUser();

  const { isAuthenticated } = useAuth();

  const { locale } = useLocale();

  const { lang } = user || {};

  const language =
    (isAuthenticated ? lang : locale) ||
    localStorage.getItem("languageSelect") ||
    detectBrowserLanguage()?.split("-")[0] ||
    "en";

  LANG = language;

  return language;
}

/**
 *
 * @param root0
 * @param root0.children
 */
export default function I18nProvider({ children }) {
  const locale = useLang();
  const [textDomain, setTextDomain] = useState(buildTextDomain());
  useEffect(() => {
    let profile = {
      catalog: {},
      plural: "n != 1",
    };

    try {
      // eslint-disable-next-line no-undef
      profile = require(`../../../../languages/LC_MESSAGES/${locale}.mo.json`);

      // const localeFile =  import(
      //   `../../../../languages/LC_MESSAGES/${locale}.mo.json`
      //   );

      // profile = localeFile.default;

      setDefaultLocale(locale);
      moment.locale(locale);
    } catch {
      // do nothing, default pofile will be used
    }
    setTextDomain(buildTextDomain(profile.catalog, profile.plural));
  }, [children, locale, setTextDomain]);

  // const messages = allMessages[locale];

  // return (
  //   <>{children}</>
  // );

  return (
    <>
      {/* <TextDomainContext.Provider value={textDomain}> */}
      {/*   {children} */}
      {/* </TextDomainContext.Provider> */}
      <TextDomainContext.Provider value={textDomain}>
        {/* <IntlProvider locale={locale} messages={messages}> */}
        {children}
        {/* </IntlProvider> */}
      </TextDomainContext.Provider>
    </>
  );
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
