import { formatDateDayMonthYearTime, toLocaleNumber } from "../";

export const energyMonthlyColumns = (data, gettext, locale, from = "month") => {
  const columns = [];
  if (from === "month") {
    // from month data
    // const consumptionData = data?.consumptionEnergyData?.consumptionData;
    const metersData = data?.monthlyStatementData?.metersInfo;
    if (Array.isArray(metersData) && metersData.length > 0) {
      columns.push(dateColumn(gettext));
      columns.push(numberColumn("sum", gettext("Sum"), locale, 150));
      // const dataForColumns = data.monthlyStatementData.metersData;
      metersData.map((obj) => {
        columns.push(numberColumn(obj.meter, obj.meter, locale));
        return null;
      });
    }
  } else {
    // from year data
    columns.push(dateColumn(gettext));
    columns.push(numberColumn("sum", gettext("Sum"), locale, 150));
    data.map((obj) => {
      columns.push(numberColumn(obj.meter, obj.meter, locale));
      return null;
    });
  }

  return columns;
};

const dateColumn = (gettext) => {
  return {
    field: "ts",
    headerName: gettext("Installation Numbers"),
    // flex: 1,
    type: "date",
    width: 150,
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        if (value === "summary") {
          returnValue = gettext("Period/Sum");
        } else {
          // returnValue = value;
          returnValue = new Date(value);
        }
      }
      return returnValue;
    },
    valueFormatter: (value) => {
      let returnValue = "";
      if (value) {
        if (Object.prototype.toString.call(value) === "[object Date]") {
          // returnValue = value;
          returnValue = formatDateDayMonthYearTime(value);
        } else {
          returnValue = value;
        }
      }
      return returnValue;
    },
  };
};

const numberColumn = (field, headerName, locale, width = 200) => {
  return {
    field,
    headerName,
    width,
    type: "number",
    valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    cellClassName: field === "sum" ? "bold" : "",
  };
};
