import md5 from "md5";
// import {getById} from "../../app/common/IndexedDb";
import { indexedDbGetById } from "../indexedDb";

export const getCacheWithoutExpiry = (key) => {
  return new Promise((resolve, reject) => {
    key = md5(key);
    indexedDbGetById("cache", key)
      .then((row) => {
        if (typeof row.value !== "undefined") {
          resolve(row.value);
        } else {
          reject(row);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });
};
