import React, {useContext, useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import {IoCheckmark, IoChevronDown} from "react-icons/io5";
import SearchIcon from "@mui/icons-material/Search";
import {useTextDomainContext} from "../../providers";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
import md5 from "md5";

/**
 * TODO: Description here
 * @param {object} props Prop
 * @returns {React.JSX.Element} component
 */
export function FabekeDropdown(props) {
  // const { onChange } = props;
  const onChangeRef = useRef(props.onChange);
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let cacheKey = "";
  if (props.componentLabel) {
    cacheKey = `FabekeDropdown-${props.componentLabel}`;
  } else if (props.options) {
    cacheKey = `FabekeDropdown-${md5(props.options)}`;
  }

  const [searchValue, setSearchValue] = useState("");
  const [newData, setNewData] = useState(
    props.options
      ? props.options
      : [
          {
            value: "",
            label: gettext("Select"),
          },
        ],
  );
  const [options, setOptions] = useState([
    {
      value: "",
      label: gettext("Select"),
    },
  ]);

  const [selectedValue, setSelectedValue] = useState(
    // localFilters ? localFilters.buildingMeterValue : gettext("all")
    "",
  );

  const [selectedLabel, setSelectedLabel] = useState(
    gettext("Select"),
    // localFilters ? localFilters.buildingMeterLabel : gettext("Select")
  );

  const handleChangeSelectedValue = (value) => {
    if (cacheKey && value) {
      localStorage.setItem(cacheKey, value);
    }
    setSelectedValue(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchValues = (value) => {
    setSearchValue(value);
    if (!value) {
      setNewData(options);
    } else {
      const filteredData = options.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setNewData(filteredData);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.value);
    setSelectedLabel(event.label);
    handleChangeSelectedValue(event.value);
  };

  useEffect(() => {
    if (selectedValue) {
      const defaultIndex = options.findIndex((x) => x.value === selectedValue);
      if (defaultIndex > -1) {
        const selected = options[defaultIndex];
        if (typeof onChangeRef.current === "function") {
          if (!isEqual(selectedValue, selected.value)) {
            onChangeRef.current(selected);
          }
        }
      }
    }
  }, [options, selectedValue]);

  useEffect(() => {
    if (props?.options && props.options.length > 0) {
      if (!isEqual(options, props.options)) {
        setOptions(props.options);
        setNewData(props.options);
      }
    }
  }, [options, props.options]);

  useEffect(() => {
    const firstSelectedValue = (value) => {
      const selectedIndex = props.options.findIndex((x) => x.value === value);
      if (selectedIndex > -1) {
        const selectedOption = props.options[selectedIndex];
        // if (!isEqual(selectedOption.value, selectedValue)) {
        setSelectedValue(selectedOption.value);
        setSelectedLabel(selectedOption.label);
        if (typeof onChangeRef.current === "function") {
          onChangeRef.current(selectedOption);
        }
        // }
      } else {
        const defaultIndex = props.options.findIndex(
          (x) => x.value === props.defaultValue,
        );
        if (defaultIndex > -1) {
          const selectedOption = props.options[defaultIndex];
          // if (!isEqual(selectedOption.value, selectedValue)) {
          setSelectedValue(selectedOption.value);
          setSelectedLabel(selectedOption.label);
          if (typeof onChangeRef.current === "function") {
            onChangeRef.current(selectedOption);
          }
          // }
        }
      }
    };

    const cacheValue = localStorage.getItem(cacheKey);

    if (cacheValue) {
      firstSelectedValue(cacheValue);
    } else if (props.defaultValue) {
      firstSelectedValue(props.defaultValue);
    }
  }, [cacheKey, props.defaultValue, props.options, selectedValue]);

  return (
    <Grid item xs={12} sm={"auto"}>
      <Button
        id="basic-button-2"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        // color="secondary"
        variant="outlined"
        onClick={handleClick}
        sx={{
          // color: theme.palette.grey[600],
          height: "32px",
        }}
      >
        <Typography
          color="grey.600"
          fontWeight="fontWeightMedium"
          fontSize="0.875rem"
        >
          {props.componentLabel}
        </Typography>
        {selectedLabel && (
          <Chip
            sx={{
              ml: 0.5,
              backgroundColor: "transparent",
              // backgroundColor: "primary.50",
              // color: "primary.main",
              fontSize: "0.875rem",
            }}
            size="small"
            label={
              <Typography
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                {gettext(selectedLabel)}
              </Typography>
            }
          />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            scrollbarWidth: "thin",
            boxSizing: "borderBox",
            position: "relative",
            width: "282px",
            maxHeight: "264px",
            left: "6403px",
            top: "310px",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            boxShadow:
              "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            "*::-webkit-scrollbar": {
              position: "absolute",
              width: "0.1em",
              height: "240px",
              left: "224px",
              top: "8px",
            },
            "*::-webkit-scrollbar-track": {
              backgroundColor: "#E4E4E7",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "#27272A",
              outline: "2px solid #27272A",
              height: "64px",
            },
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "0.875rem",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            mx: 1,
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#FFFFFF",
            // color: "#064b7c"
          },
        }}
      >
        <Stack spacing={1}>
          <OutlinedInput
            sx={{
              color: "grey.600",
              mx: 1,

              "& .css-hq24ym-MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px",
              },
            }}
            size="small"
            value={searchValue}
            onChange={(e) => handleSearchValues(e.target.value)}
            // onFocus={() => setAddMode(true)}
            placeholder={gettext("Search")}
            startAdornment={
              <InputAdornment
                position={"start"}
                style={
                  {
                    // color: "secondary.300"
                  }
                }
              >
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box
            sx={
              {
                // height: "265px",
                // overflow: "auto",
                // mx: 1,
              }
            }
          >
            {newData && newData.length > 0 ? (
              newData?.map((option, index) => (
                <MenuItem
                  key={option.value + "_" + index}
                  selected={selectedValue === option.value}
                  title={gettext(option.label)}
                  onClick={() => {
                    handleChangeSelectedValue(option.value);
                    setSelectedLabel(option.label);
                    handleChange(option);
                    // props.onChange(option);
                    handleClose();
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: selectedValue === option.value ? "90%" : "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {gettext(option.label)}
                  </Typography>

                  {selectedValue === option.value && (
                    <IoCheckmark size={14} color="#064b7c" />
                  )}
                </MenuItem>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  margin: "12px",
                }}
              >
                {gettext("Not Found")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Menu>
    </Grid>
  );
}

FabekeDropdown.propTypes = {
  componentLabel: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};
