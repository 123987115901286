import { gql } from "graphql-tag";

export const GET_METER_BROADCAST_DATA = gql`
  query metersBroadcastData(
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $fileType: String!
  ) {
    metersBroadcastData(
      meterType: $meterType
      meteringPointId: $meteringPointId
      address: $address
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
      fileType: $fileType
    ) {
      Id
      address
      meteringPointId
      ident
      customer
      cvr
      ident
      meteringPointId
      unit
      type
      typeOfMP
      electricityGreen
      data {
        co2PerM2
        consumptionCo2
        consumptionV
        productionCo2
        productionV
        ts
        vPerM2
      }
      colorSettings {
        electricity {
          background
          text
        }
        heating {
          background
          text
        }
        water {
          background
          text
        }
        gas {
          background
          text
        }
        energy {
          background
          text
        }
        co2 {
          background
          text
        }
      }
    }
  }
`;