import {
  indexedDbDeleteAll,
  indexedDbDeleteById,
  indexedDbGetById,
  indexedDbUpdate,
} from "../../../utils";

class IndexedDBWrapper {
  constructor(storeName) {
    this.storeName = storeName;
  }

  async getItem(key) {
    try {
      const result = await indexedDbGetById(this.storeName, key);
      return result ? result.value : null;
    } catch {
      // console.error(`Error getting item from ${this.storeName}:`, error);
      return null;
    }
  }

  async setItem(key, value) {
    try {
      const data = {
        key,
        value,
      };
      await indexedDbUpdate(this.storeName, data, key);
    } catch {
      // console.error(`Error setting item in ${this.storeName}:`, error);
    }
  }

  async removeItem(key) {
    try {
      await indexedDbDeleteById(this.storeName, key);
    } catch {
      // console.error(`Error removing item from ${this.storeName}:`, error);
    }
  }

  async clear() {
    try {
      await indexedDbDeleteAll([this.storeName]);
    } catch {
      // console.error(`Error clearing store ${this.storeName}:`, error);
    }
  }
}

export default IndexedDBWrapper;
