import { useLang, useTextDomainContext, useUser } from "../../providers";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  formatDateForAPI,
  formatDateYear,
  getPreviousYearDate,
  segmentList,
  toBenchmarkDataEnergy,
  toLocaleNumber,
  urlFriendlyString,
} from "../../../utils";
import { useEnergyData } from "../../../hooks";
import { isEqual } from "lodash";
import { FilterBar1, LineChartBox, TableView1 } from "../../components";

// const useStyles = makeStyles((theme) => ({
//   totalRow: {
//     fontWeight: 'bold',
//     backgroundColor: theme.palette.grey[200], // Adjust color to match header row
//     color: theme.palette.text.primary, // Adjust color to match header row
//   },
// }));

export const EnergyConsumption = () => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const { user } = useUser();
  const segments = segmentList();
  let { currentTeam: customer, currentSubsidiary: group } = user || {};
  const theme = useTheme();
  const locale = useLang();
  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  //region React Hooks (useState)
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("electricity");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [segment, setSegment] = useState("year");
  const [shortSegment, setShortSegment] = useState("m");
  const [chartData, setChartData] = useState([]);
  const [fileNameForExport, setFileNameForExport] = useState("energy-export");
  const [tableData, setTableData] = useState([]);
  // const [thisYearData, setThisYearData] = useState([]);
  // const [previousYearData, setPreviousYearData] = useState([]);
  //endregion  React Hooks (useState)

  //region Data
  // const [getMeterData] = useLazyQuery(ENERGY_DATA);

  // const filters = {
  //   customer,
  //   group,
  //   address: building,
  //   meteringPointId: meter,
  //   meterType: energyType,
  //   fromDate: formatDateForAPI(fromDate),
  //   toDate: formatDateForAPI(toDate),
  //   resolution: shortSegment,
  // };

  const thisYearFilter = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: formatDateForAPI(fromDate),
    toDate: formatDateForAPI(toDate),
    resolution: shortSegment,
  };

  const previousYearFilter = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: getPreviousYearDate(thisYearFilter.fromDate),
    toDate: getPreviousYearDate(thisYearFilter.toDate),
    resolution: shortSegment,
  };

  // const filters = useMemo(() => {
  //   return {
  //     customer,
  //     group,
  //     address: building,
  //     meteringPointId: meter,
  //     meterType: energyType,
  //     fromDate: formatDateForAPI(fromDate),
  //     toDate: formatDateForAPI(toDate),
  //     resolution: shortSegment,
  //   };
  // }, [customer, group, building, energyType, fromDate, meter, shortSegment, toDate]);

  // const {
  //   data: metersData,
  //   loading
  // } = useEnergyData(filters);

  const {
    data: metersDataThisYear,
    total: totalThisYear,
    loadingThisYear,
  } = useEnergyData(thisYearFilter);

  const {
    data: metersDataPreviousYear,
    total: totalPreviousYear,
    loadingPreviousYear,
  } = useEnergyData(previousYearFilter);

  // if (!loadingThisYear) {
  //   console.log("This Year", metersDataThisYear, totalThisYear);
  // }
  //
  // if (!loadingPreviousYear) {
  //   console.log("Previous Year", metersDataPreviousYear, totalPreviousYear);
  // }

  //endregion Data

  //region React Hooks (useEffect)

  useEffect(() => {
    const calculatedTableData = toBenchmarkDataEnergy(
      shortSegment,
      [
        {
          data: metersDataThisYear,
          total: totalThisYear,
        },
        {
          data: metersDataPreviousYear,
          total: totalPreviousYear,
        },
      ],
      gettext,
      true,
    );

    const calculatedChartData = toBenchmarkDataEnergy(
      shortSegment,
      [
        {
          data: metersDataThisYear,
          total: totalThisYear,
        },
        {
          data: metersDataPreviousYear,
          total: totalPreviousYear,
        },
      ],
      false,
    );

    if (!isEqual(tableData, calculatedTableData)) {
      setTableData(calculatedTableData);
    }

    if (!isEqual(chartData, calculatedChartData)) {
      setChartData(calculatedChartData);
    }
  }, [
    chartData,
    gettext,
    metersDataPreviousYear,
    metersDataThisYear,
    shortSegment,
    tableData,
    totalPreviousYear,
    totalThisYear,
  ]);

  // useEffect(() => {
  //   console.log(thisYearFilter, previousYearFilter);
  // }, [thisYearFilter, previousYearFilter]);

  // useEffect(() => {
  //   if (customer && group && building && meter && energyType && fromDate && toDate && shortSegment) {
  //     const thisYearFilter = {
  //       customer,
  //       group,
  //       address: building,
  //       meteringPointId: meter,
  //       meterType: energyType,
  //       fromDate: formatDateForAPI(fromDate),
  //       toDate: formatDateForAPI(toDate),
  //       resolution: shortSegment,
  //     };
  //     // console.log(thisYearFilter);
  //     getMeterData({variables: thisYearFilter}).then(({data}) => {
  //       console.log(thisYearFilter, data);
  //     });
  //     const previousYearFilter = {
  //       customer,
  //       group,
  //       address: building,
  //       meteringPointId: meter,
  //       meterType: energyType,
  //       fromDate: getPreviousYearDate(thisYearFilter.fromDate),
  //       toDate: getPreviousYearDate(thisYearFilter.toDate),
  //       resolution: shortSegment,
  //     };
  //     // console.log(previousYearFilter);
  //     getMeterData({variables: previousYearFilter}).then(({data}) => {
  //       console.log(previousYearFilter, data);
  //     });
  //   } else {
  //     console.log(customer, group, building, meter, energyType, fromDate, toDate, shortSegment);
  //   }
  // }, [customer, group, building, energyType, fromDate, meter, shortSegment, toDate]);

  useEffect(() => {
    setFileNameForExport(
      urlFriendlyString(
        "energy-" +
          building +
          "-" +
          meter +
          "-" +
          energyType +
          "-" +
          segment +
          "-" +
          fromDate +
          "-" +
          toDate,
      ),
    );
  }, [building, energyType, fromDate, meter, segment, toDate]);

  // useEffect(() => {
  //   if (metersData) {
  //     const chartDateTemp = meterApiDataToBarChartData(metersData, shortSegment);
  //     if (!isEqual(chartDateTemp, chartData)) {
  //       setChartData(meterApiDataToBarChartData(metersData, shortSegment));
  //     }
  //   }
  // }, [chartData, metersData, shortSegment]);

  //endregion React Hooks (useEffect)

  //region For Table

  // const columns = [
  //   {
  //     field: "ts",
  //     headerName: gettext("Date"),
  //     flex: 1,
  //     type: "date",
  //     valueGetter: (value) => {
  //       let returnValue = "";
  //       if (value) {
  //         if (value === "Summary") {
  //           returnValue = value;
  //         } else {
  //           returnValue = new Date(value);
  //         }
  //       }
  //       return returnValue;
  //     },
  //     valueFormatter: (value) => {
  //       let returnValue = "";
  //       if (value) {
  //         if (value === "Summary") {
  //           returnValue = value;
  //         } else {
  //           // returnValue = capitalizeFirstLetter(moment(value).format(segments[segment].dateFormat));
  //           returnValue = formatDateBySegment(value, segment);
  //         }
  //       }
  //       return returnValue;
  //     }
  //   },
  //   {
  //     field: "consumptionV",
  //     headerName: gettext("Consumption (kWh)"),
  //     flex: 1,
  //     type: "number",
  //     valueFormatter: (value) => toLocaleNumber(locale, value, 2)
  //   },
  //   // {
  //   //   field: "consumptionConversionRate",
  //   //   headerName: gettext("Conversion Rate (g CO₂/kWh)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(2)
  //   // },
  //   // {
  //   //   field: "consumptionCo2",
  //   //   headerName: gettext("CO₂ (kilogram)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(0)
  //   // }
  //   // {
  //   //   field: "consumptionCost",
  //   //   headerName: gettext("Cost (DKK)"),
  //   //   flex: 1,
  //   //   type: "number"
  //   // }
  // ];

  const columns = [
    {
      field: "ts",
      headerName: gettext("Date"),
      flex: 1,
      // rowClassName: (params) => {
      //   return params.value === "Total" ? "MuiDataGrid-columnHeaders" : '';
      // }
      // cellClassName: (params) => {
      //   return params.value === gettext("Total") ? "MuiDataGrid-columnHeaders" : '';
      // }
      // valueFormatter: (value) => {
      //   let returnValue = "";
      //   if (value) {
      //     if (value === gettext("Total")) {
      //       returnValue = value;
      //     } else {
      //       returnValue = value;
      //       // returnValue = capitalizeFirstLetter(moment(value).format(segments[segment].dateFormat));
      //       // returnValue = formatDateBySegment(value, segment);
      //     }
      //   }
      //   return returnValue;
      // }
    },
    {
      field: formatDateYear(getPreviousYearDate(thisYearFilter.fromDate)),
      headerName: formatDateYear(getPreviousYearDate(thisYearFilter.fromDate)),
      flex: 1,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    },
    {
      field: formatDateYear(formatDateForAPI(fromDate)),
      headerName: formatDateYear(formatDateForAPI(fromDate)),
      flex: 1,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    },

    // {
    //   field: "consumptionConversionRate",
    //   headerName: gettext("Conversion Rate (g CO₂/kWh)"),
    //   flex: 1,
    //   type: "number",
    //   valueFormatter: (value) => value && value.toFixed(2)
    // },
    // {
    //   field: "consumptionCo2",
    //   headerName: gettext("CO₂ (kilogram)"),
    //   flex: 1,
    //   type: "number",
    //   valueFormatter: (value) => value && value.toFixed(0)
    // }
    // {
    //   field: "consumptionCost",
    //   headerName: gettext("Cost (DKK)"),
    //   flex: 1,
    //   type: "number"
    // }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50,
      },
    },
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };
  //endregion For Table

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }

    if (selectedValues?.segment) {
      setSegment(selectedValues.segment);
      setShortSegment(segments[selectedValues.segment].shortSegment);
      // if (
      //   selectedValues.segment === "day" &&
      //   moment(selectedValues?.date?.startDate).format("YYYY-MM-DD") !==
      //     moment(selectedValues?.date?.endDate).format("YYYY-MM-DD")
      // ) {
      //   setSegment("month");
      //   setShortSegment(segments["month"].shortSegment);
      // }
    }
    // console.log(
    //   building,
    //   meter,
    //   energyType,
    //   fromDate,
    //   toDate,
    //   segment,
    //   shortSegment,
    //   selectedValues,
    // );
  };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        showBuilding
        showMeter
        showEnergyType
        showDateSingle
        // showDateMultiple
        showBuildingAll
        showMeterAll
        // showEnergyTypeAll
      />
      <br />

      {/*<BarChartBox*/}
      {/*  loading={loading}*/}
      {/*  title={gettext("Consumption measured in kWh")}*/}
      {/*  chartId={"consumption"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [{*/}
      {/*      dataKey: "consumptionV",*/}
      {/*      label: gettext("Consumption"),*/}
      {/*      color: theme.palette.chart.electricity,*/}
      {/*      unit: gettext("kWh"),*/}
      {/*    }]*/}
      {/*  }}*/}
      {/*/>*/}

      <LineChartBox
        loading={loadingThisYear || loadingPreviousYear}
        title={gettext("Consumption measured in kWh")}
        chartId={"consumption"}
        data={{
          dataset: chartData,
          series: [
            {
              dataKey: new Date(previousYearFilter.fromDate)
                .getFullYear()
                .toString(),
              label: new Date(previousYearFilter.fromDate)
                .getFullYear()
                .toString(),
              color: theme.palette.chart.year2,
              unit: gettext("Consumption is kWh"),
            },
            {
              dataKey: new Date(thisYearFilter.fromDate)
                .getFullYear()
                .toString(),
              label: new Date(thisYearFilter.fromDate).getFullYear().toString(),
              color: theme.palette.chart.year1,
              unit: gettext("Consumption is kWh"),
            },
          ],
        }}
      />
      <br />

      <TableView1
        title={gettext("Electricity consumption measured in kWh")}
        rows={tableData}
        // rows={[]}
        columns={columns}
        loading={loadingThisYear || loadingPreviousYear}
        initialState={initialState}
        showGridToolbar
        showGridToolbarExport
        fileNameForExport={fileNameForExport}
        disableColumnSorting
        disableColumnSelector
        // getRowClassName={(params) => {
        //   console.log(params.isLastVisible);
        //   return "MuiDataGrid-columnHeaders";
        //   // return params.row.value === "Total" ? "MuiDataGrid-columnHeaders" : '';
        // }}
      />
    </>
  );
};
