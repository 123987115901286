import {
  Avatar,
  Box,
  Button,
  Divider,
  Popover,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTextDomainContext, useUser } from "../../../../../../../providers";
import { Link } from "react-router-dom";
import { nameInitial } from "../../../../../../../../utils";
import { ProfileMenu } from "./profileMenu";

export const UserTopBox = (props) => {
  //region Data
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const { user } = useUser();
  const {
    // isVerified,
    profilePhotoUrl,
  } = user || {};
  //endregion Data

  //region React Hooks (useState)
  const [anchorEl, setAnchorEl] = useState(null);
  const [photo, setPhoto] = React.useState("");

  const open = Boolean(anchorEl);
  //endregion React Hooks (useState)

  //region React Hooks (useEffect)
  useEffect(() => {
    setAnchorEl(props.anchorEl);
  }, [props.anchorEl]);

  useEffect(() => {
    profilePhotoUrl && setPhoto(profilePhotoUrl);
  }, [profilePhotoUrl]);
  //endregion React Hooks (useEffect)

  //region Handlers
  const handleClose = () => {
    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };
  //endregion Handlers

  return (
    <Popover
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      // PaperProps={{
      //   style: {
      //     width: 400
      //   },
      //   elevation: 0,
      //   sx: {
      //     overflow: "visible",
      //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      //     mt: 0,
      //     "& .MuiAvatar-root": {
      //       width: 82,
      //       height: 82,
      //       ml: 0,
      //       mr: 0
      //     },
      //     "&:before": {
      //       content: "\"\"",
      //       display: "block",
      //       position: "absolute",
      //       top: 0,
      //       right: 100,
      //       width: 10,
      //       height: 10,
      //       bgcolor: "background.paper",
      //       transform: "translateY(-50%) rotate(45deg)",
      //       zIndex: 0
      //     }
      //   }
      // }}

      slotProps={{
        paper: {
          sx: {
            minWidth: 300,
            maxWidth: "100%",
            backgroundColor: "primary.main",
            "&::-webkit-scrollbar": {
              width: "8px",
              // backgroundColor: "primary.700",
              // backgroundColor: "primary.main",
              // background: `linear-gradient(to bottom, ${primaryDark}, ${primaryLight})`,
              // backgroundColor: primaryLight // Optional: Set background color for the scrollbar track
            },
            "&::-webkit-scrollbar-thumb": {
              // backgroundColor: "rgba(0, 255, 0, 0.3)", // Set background color for the scrollbar thumb
              // backgroundColor: primaryDark, // Set background color for the scrollbar thumb
              backgroundColor: "primary.700",
              // background: `linear-gradient(to bottom, ${primaryDark}, ${primaryLight})`,
              borderRadius: "8px", // Optional: Add border radius to the thumb
            },
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          // height: 220,
          // width: 330,
          // overflow: "auto",
          cursor: "auto",
          // background: "#F5F7F9",
          // backgroundColor: (theme) => theme.palette.grey[50],
          display: "block",
          color: "primary.100",
          p: 2,
        }}
      >
        <Box textAlign="center">{user?.email}</Box>
        {/*<div className="d-flex flex-row ">*/}
        {/*<Toolbar>*/}
        <Box
          justifyContent="center"
          display="flex"
          sx={{
            p: 2,
          }}
        >
          <Avatar
            alt={user?.name}
            src={photo}
            sx={{
              height: 100,
              width: 100,
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "primary.100",
            }}
          >
            {nameInitial(user?.name)}
          </Avatar>
        </Box>
        {/*</Toolbar>*/}

        <Box>
          <Typography
            variant="h5"
            className="font-weight-bold"
            textAlign="center"
            color="primary.100"
          >
            {user?.name}
          </Typography>
          {/*<div className="d-flex flex-wrap">*/}
          {/*  {user?.roles?.map((role, index) => (*/}
          {/*    <Chip*/}
          {/*      className="m-1"*/}
          {/*      variant="outlined"*/}
          {/*      size="small"*/}
          {/*      label={role}*/}
          {/*      key={`Chip-${role}`}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</div>*/}
          {/*{isVerified ? (*/}
          {/*  <Box>*/}
          {/*    <ListItemIcon>*/}
          {/*      <EmailIcon fontSize="small" className="mt-3"/>*/}
          {/*    </ListItemIcon>*/}
          {/*    {user?.email}*/}
          {/*  </Box>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <Box sx={{*/}
          {/*      display: "flex",*/}
          {/*      alignItems: "center"*/}
          {/*    }}>*/}
          {/*      <ListItemIcon>*/}
          {/*        <EmailIcon fontSize="small"/>*/}
          {/*      </ListItemIcon>*/}
          {/*      <Box sx={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column"*/}
          {/*      }}>*/}
          {/*        <Typography>{user?.email}</Typography>*/}
          {/*        <Typography sx={{*/}
          {/*          color: "red",*/}
          {/*          fontSize: 12*/}
          {/*        }}>*/}
          {/*          {gettext("Email Not Verified")}*/}
          {/*        </Typography>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*  </>*/}
          {/*)}*/}

          {/*<Box>*/}
          {/*  <ListItemIcon>*/}
          {/*    <LocationOnIcon fontSize="small"/>*/}
          {/*  </ListItemIcon>*/}
          {/*  {user?.countryCode}*/}
          {/*</Box>*/}
        </Box>
        {/*</div>*/}
        <Box
          justifyContent="center"
          display="flex"
          sx={{
            pt: 2,
          }}
        >
          <Button
            component={Link}
            to="/user-profile/personal-information"
            variant="outlined"
            sx={{
              borderColor: "primary.100",
              color: "primary.100",
              borderRadius: 20,
              px: 10,
              "&:hover": {
                transform: "translateY(0)",
                borderColor: "primary.50",
                color: "primary.50",
              },
            }}
          >
            {gettext("My Profile")}
          </Button>
        </Box>
      </Box>

      <Divider color="primary.100" />
      <Box
        sx={{
          p: 1,
        }}
      >
        <ProfileMenu />
      </Box>
    </Popover>
  );
};

UserTopBox.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};
