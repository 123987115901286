import React, { useContext, useEffect, useMemo, useState } from "react";
import { FabekeDropdown } from "./FabekeDropdown";
import PropTypes from "prop-types";
import { useTextDomainContext } from "../../providers";
import { isEqual } from "lodash";

export const CommonDropdown = (props) => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const optionAll = useMemo(() => {
    return [
      {
        value: "all",
        label: gettext("All"),
      },
    ];
  }, [gettext]);

  const optionList = props.options;

  const propsDefault = {
    defaultValue: "all",
    showAll: true,
    componentLabel: "",
  };

  const [componentLabel, setComponentLabel] = useState(
    props.componentLabel ? props.componentLabel : propsDefault.componentLabel,
  );
  const [showAll, setShowAll] = useState(
    typeof props.showAll === "undefined" ? propsDefault.showAll : props.showAll,
  );
  const [selectedValue, setSelectedValue] = useState(
    props.defaultValue ? props.defaultValue : propsDefault.defaultValue,
  );
  const [options, setOptions] = useState(
    props.showAll ? [...optionAll, ...optionList] : optionList,
  );

  //region React Hooks (useEffect)
  useEffect(() => {
    if (typeof props.defaultValue !== "undefined") {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (showAll) {
      const newOptions = [...optionAll, ...optionList];
      if (!isEqual(newOptions, options)) {
        setOptions(newOptions);
        setSelectedValue(
          props.defaultValue ? props.defaultValue : propsDefault.defaultValue,
        );
      }
    } else {
      if (!isEqual(optionList, options)) {
        setOptions(optionList);
      }

      const firstSelectedValue = optionList.find(
        (o) => o.value === selectedValue,
      );

      if (firstSelectedValue) {
        if (!isEqual(selectedValue, firstSelectedValue?.value)) {
          setSelectedValue(firstSelectedValue.value);
        }
      } else {
        if (!isEqual(selectedValue, optionList[0]?.value)) {
          setSelectedValue(optionList[0].value);
        }
      }
    }
  }, [
    optionAll,
    optionList,
    options,
    props.defaultValue,
    propsDefault.defaultValue,
    selectedValue,
    showAll,
  ]);

  useEffect(() => {
    if (typeof props.componentLabel !== "undefined") {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);
  //endregion React Hooks (useEffect)

  const handleOnChange = (event) => {
    if (!isEqual(event.value, selectedValue)) {
      setSelectedValue(event.value);
    }
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };

  return (
    <FabekeDropdown
      options={options}
      componentLabel={componentLabel}
      defaultValue={selectedValue}
      onChange={handleOnChange}
    />
  );
};

CommonDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
