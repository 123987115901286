import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoCalendar, IoChevronDown } from "react-icons/io5";
import "./Styles/DatePickerDmy.css";
import PropTypes from "prop-types";
import { useTextDomainContext } from "../../providers";

export const MultiDatePickerDmy = (props) => {
  // const { getSelectedDate } = props;
  const getSelectedDateRef = useRef(props.getSelectedDate);
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const localStorageKey = "MultiDatePickerDmy";
  const theme = useTheme();

  const cacheValues = JSON.parse(localStorage.getItem(localStorageKey));

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  defaultStartDate.setDate(1);
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);

  defaultEndDate.setDate(1);
  defaultEndDate.setDate(defaultEndDate.getDate() - 1);

  let defaultSegmentIndex = 0;
  let defaultSegmentName = "year";

  if (cacheValues?.startDate) {
    defaultStartDate = new Date(cacheValues.startDate);
  } else if (typeof props.startDate !== "undefined") {
    defaultStartDate = new Date(props.startDate);
  }

  if (cacheValues?.endDate) {
    defaultEndDate = new Date(cacheValues.endDate);
  } else if (typeof props.endDate !== "undefined") {
    defaultEndDate = new Date(props.endDate);
  }

  if (cacheValues?.segmentIndex) {
    defaultSegmentIndex = cacheValues.segmentIndex;
  } else if (typeof props.segmentIndex !== "undefined") {
    defaultSegmentIndex = new Date(props.segmentIndex);
  }

  if (cacheValues?.segmentName) {
    defaultSegmentName = cacheValues.segmentName;
  } else if (typeof props.segmentName !== "undefined") {
    defaultSegmentName = new Date(props.segmentName);
  }

  const propsDefault = {
    note: gettext("Select date range"),
    selectedDate: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
    segmentIndex: defaultSegmentIndex,
    segmentName: defaultSegmentName,
  };

  //region UseState [React Hooks]
  const [selectedSegment, setSelectedSegment] = useState(
    propsDefault.segmentName,
  );
  const [startDate, setStartDate] = useState(
    propsDefault.selectedDate.startDate,
  );
  const [endDate, setEndDate] = useState(propsDefault.selectedDate.endDate);
  const [anchorEl, setAnchorEl] = useState(null);
  const [segmentIndex, setSegmentIndex] = useState(propsDefault.segmentIndex);
  //endregion useState

  //region useEffect [React Hooks]
  useEffect(() => {
    const start = startDate;
    const end = endDate;
    if (start && end) {
      let dateStart = start;
      let dateEnd = end;

      const startYear = start.getFullYear();
      const startMonth = start.getMonth();
      const startDay = start.getDate();

      const endYear = end.getFullYear();
      const endMonth = end.getMonth();
      const endDay = end.getDate();

      switch (selectedSegment) {
        case "year":
          dateStart = new Date(startYear, 0, 1, 0, 0, 0);
          dateEnd = new Date(new Date(endYear + 1, 0, 1, 0, 0, 0) - 1);
          break;
        case "month":
          dateStart = new Date(startYear, startMonth, 1, 0, 0, 0);
          dateEnd = new Date(new Date(endYear, endMonth + 1, 1, 0, 0, 0) - 1);
          break;
        default:
          dateStart = new Date(startYear, startMonth, startDay, 0, 0, 0);
          dateEnd = new Date(
            new Date(endYear, endMonth, endDay + 1, 0, 0, 0) - 1,
          );
          break;
      }

      if (typeof getSelectedDateRef.current === "function") {
        getSelectedDateRef.current(
          {
            startDate: dateStart,
            endDate: dateEnd,
          },
          selectedSegment,
        );
      }

      // if (typeof getSelectedDate === "function") {
      //   getSelectedDate(
      //     {
      //       startDate: dateStart,
      //       endDate: dateEnd,
      //     },
      //     selectedSegment,
      //   );
      // }
      handleSegmentClose();
    }
  }, [endDate, selectedSegment, startDate]);

  // useEffect(() => {
  //   const item = {
  //     startDate,
  //     endDate,
  //     segmentIndex,
  //     selectedSegment
  //   };
  //   localStorage.setItem(localStorageKey, JSON.stringify(item));
  // }, [endDate, segmentIndex, selectedSegment, startDate]);

  //endregion useEffect

  //region Handlers
  const handleSegmentClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSegmentClose = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const item = {
        startDate: start,
        endDate: end,
        segmentIndex,
        selectedSegment,
      };

      localStorage.setItem(localStorageKey, JSON.stringify(item));

      handleSegmentClose();
    }
  };

  const handleSegmentSelect = (segmentName) => {
    setSelectedSegment(segmentName);
    let selectedSegmentIndex = 2;
    if (segmentName === "day") {
      selectedSegmentIndex = 0;
      // setSegmentIndex(0);
    } else if (selectedSegment === "month") {
      selectedSegmentIndex = 1;
      // setSegmentIndex(1);
    } else {
      selectedSegmentIndex = 2;
      // setSegmentIndex(2);
    }
    setSegmentIndex(selectedSegmentIndex);

    const item = {
      startDate,
      endDate,
      selectedSegmentIndex,
      segmentName,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(item));
    handleSegmentClose();
  };
  //endregion Handlers

  const formattedDate = () => {
    let dateStart = new Date();
    let dateEnd = new Date();
    if (startDate) {
      dateStart = startDate;
    }

    if (endDate) {
      dateEnd = endDate;
    }

    const dayStart = dateStart.getDate();
    const monthStart = dateStart.toLocaleString("default", {
      month: "short",
    });
    const yearStart = dateStart.getFullYear();

    const dayEnd = dateEnd.getDate();
    const monthEnd = dateEnd.toLocaleString("default", {
      month: "short",
    });
    const yearEnd = dateEnd.getFullYear();

    if (selectedSegment === "year") {
      return `${yearStart} - ${yearEnd}`;
    } else if (selectedSegment === "month") {
      return `${monthStart} ${yearStart} - ${monthEnd} ${yearEnd}`;
    } else {
      return `${dayStart} ${monthStart} ${yearStart} - ${dayEnd} ${monthEnd} ${yearEnd}`;
    }
  };

  const renderDatePicker = () => {
    switch (selectedSegment) {
      case "year":
        return (
          <DatePicker
            className="my-datepicker"
            // selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="yyyy"
            showMonthDropdown={false}
            showYearPicker
            dropdownMode="select"
            selectsRange
            inline
            // minDate={
            //     futureDates === false &&
            //     new Date().setMonth(new Date().getMonth())
            // }
            maxDate={
              props.futureDates !== false
                ? new Date()
                : new Date().setMonth(new Date().getMonth() + 3)
            }
            // maxDate={futureDates !== false && new Date()}
            dayClassName={(date) => {
              return date.getMonth() !== startDate.getMonth() ? "disabled" : "";
            }}
          >
            <Typography fontSize="14px" color={"secondary.900"}>
              {propsDefault.note}
            </Typography>
          </DatePicker>
        );
      case "month":
        return (
          <DatePicker
            className="my-datepicker"
            // selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            selectsRange
            inline
            // minDate={
            //     futureDates === false &&
            //     new Date().setMonth(new Date().getMonth())
            // }
            maxDate={
              props.futureDates !== false
                ? new Date()
                : new Date().setMonth(new Date().getMonth() + 3)
            }
            dayClassName={(date) => {
              return date.getMonth() !== startDate.getMonth() ||
                date.getFullYear() !== startDate.getFullYear()
                ? "disabled"
                : "";
            }}
          >
            <Typography fontSize="14px" color={"secondary.900"}>
              {propsDefault.note}
            </Typography>
          </DatePicker>
        );
      case "day":
      default:
        return (
          <DatePicker
            // className="my-datepicker"
            // selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="dd MMMM yyyy"
            selectsRange
            inline
            maxDate={props.futureDates !== false && new Date()}
            dayClassName={(date) => {
              return date.getMonth() !== startDate.getMonth() ? "disabled" : "";
            }}
          >
            <Typography fontSize="14px" color={"secondary.900"}>
              {propsDefault.note}
            </Typography>
          </DatePicker>
        );
    }
  };

  return (
    <>
      {props.view === "table" ? (
        <Button
          endIcon={<IoChevronDown size={12} />}
          size="small"
          onClick={handleSegmentClick}
          sx={{
            width: "160px",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid #E4E4E7",
            },
          }}
        >
          <Typography fontSize="14px" fontWeight="500" color="secondary.800">
            Calender
          </Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          // color="secondary"
          style={{
            color: theme.palette.grey[600],
            height: "32px",
          }}
          endIcon={<IoCalendar />}
          onClick={handleSegmentClick}
        >
          <Typography fontSize="14px" fontWeight="fontWeightMedium" noWrap>
            {formattedDate()}
          </Typography>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSegmentClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            height: "100%",
          },
          "& .MuiMenu-paper": {
            width: "412px",
            borderRadius: "8px",
            boxShadow:
              "0px 10px 15px -3px #0000001A ,0px 4px 6px -2px #0000000F",
            overflow: "hidden",
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "secondary.900",
            fontWeight: 600,
            fontSize: "14px",
            color: "white",
          },
        }}
      >
        {/*<Grid container columnSpacing={1} xs={12}>*/}
        <Grid container columnSpacing={1}>
          <Grid
            item
            xs={4}
            sx={{
              borderRight: "1px solid #E4E4E7",
            }}
          >
            <MenuItem
              disabled={props.disableSegments && segmentIndex !== 0}
              selected={selectedSegment === "day"}
              onClick={() => handleSegmentSelect("day")}
            >
              {gettext("Hourly")}
            </MenuItem>
            <MenuItem
              disabled={props.disableSegments && segmentIndex !== 1}
              selected={selectedSegment === "month"}
              onClick={() => handleSegmentSelect("month")}
            >
              {gettext("Daily")}
            </MenuItem>
            <MenuItem
              disabled={props.disableSegments && segmentIndex !== 2}
              selected={selectedSegment === "year"}
              onClick={() => handleSegmentSelect("year")}
            >
              {gettext("Monthly")}
            </MenuItem>
          </Grid>
          <Grid item xs={8}>
            {renderDatePicker()}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

// TODO: futureDates and disableSegments is not properly working. We need to fix that
MultiDatePickerDmy.propTypes = {
  selectedDate: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  segment: PropTypes.oneOf(["day", "month", "year"]),
  view: PropTypes.string,
  futureDates: PropTypes.bool,
  disableSegments: PropTypes.bool,
  getSelectedDate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  segmentIndex: PropTypes.number,
  segmentName: PropTypes.string,
};
