import {gql} from "@apollo/client";

export const MONTHLY_STATEMENT_DATA = gql`
query MonthlyStatementData (
    $meterType:  String!,
    $customer:  String!,
    $group:  String!,
    $year:  String!,
    $isClimate: Boolean
    $month: String
  ) {
    monthlyStatementData(
      meterType: $meterType
      customer: $customer
      group: $group
      year: $year
      isClimate: $isClimate
      month: $month
    ) {
      benchmark
      month
      year
      isClimate
      customer
      group
      metersInfo {
        meter
        customer
        consumerCVR
        meterNumber
        streetName
        buildingNumber
        postcode
        cityName
        email
        address
        gridOperatorName
        customField1
        customField2
        customField3
      }
      metersData {
        meter
        data {
          ts
          consumptionV
        }
      }
    }
}
`;