import { initIDB } from "./";

export const indexedDbDeleteAll = (dbNameArray) => {
  return new Promise((resolve, reject) => {
    initIDB().then((db) => {
      dbNameArray.forEach((dbName) => {
        const txn = db.transaction(dbName, "readwrite");
        const store = txn.objectStore(dbName);

        store.clear();
        store.onerror = (err) => {
          reject(err);
        };

        txn.oncomplete = function () {
          db.close();
        };
      });
      resolve("success");
    });
  });
};
