import md5 from "md5";
import { getMonthsArray } from "../";

export const energyMonthlyRows = (data, gettext) => {
  const rows = [];

  // console.log(data);

  // let sumOfGradSum = 0;
  // const sumOfMeterSum = [];
  // const monthlyStatementData = data?.monthlyStatementData;
  const metersData = data?.monthlyStatementData?.metersData;
  const monthsArray = getMonthsArray(gettext);
  const monthIndex = data?.monthlyStatementData?.month || 0;
  const selectedMonth = monthsArray[monthIndex].value;

  // if (data?.consumptionEnergyData?.consumptionData[0]?.details?.data[0]?.meter) {
  if (Array.isArray(metersData) && metersData.length > 0) {
    metersData.map((objConsumptionData) => {
      // const monthIndex = parseInt(objConsumptionData.month.slice(-2));
      // const monthIndex = parseInt(objConsumptionData.month);
      const orderNo = objConsumptionData?.meter;

      if (!rows["all"]) {
        rows["all"] = [];
      }

      if (!rows[selectedMonth]) {
        rows[selectedMonth] = [];
      }

      // const grandSum = objConsumptionData.details.grandSum;
      // sumOfGradSum += grandSum;
      objConsumptionData.data.map((hourlyData) => {
        // const rowIndex = [];
        // const id = "summary";
        // const orderNo = data?.meter;
        // rowIndex["all"] = rows["all"].findIndex((obj) => obj.id === id);
        // rowIndex[selectedMonth] = rows[selectedMonth].findIndex(
        //   (obj) => obj.id === id,
        // );

        // if (typeof sumOfMeterSum["all"] === "undefined") {
        //   sumOfMeterSum["all"] = [];
        // }
        //
        // if (typeof sumOfMeterSum[selectedMonth] === "undefined") {
        //   sumOfMeterSum[selectedMonth] = [];
        // }
        //
        // if (typeof sumOfMeterSum["all"][orderNo] === "undefined") {
        //   sumOfMeterSum["all"][orderNo] = 0;
        // }
        //
        // if (typeof sumOfMeterSum[selectedMonth][orderNo] === "undefined") {
        //   sumOfMeterSum[selectedMonth][orderNo] = 0;
        // }
        //
        // sumOfMeterSum["all"][orderNo] += data.consumptionSum;
        // sumOfMeterSum[selectedMonth][orderNo] += data.consumptionSum;
        // if (rowIndex["all"] > -1) {
        //   rows["all"][rowIndex["all"]].sum = sumOfGradSum;
        //   rows["all"][rowIndex["all"]][orderNo] = sumOfMeterSum["all"][orderNo];
        // } else {
        //   const row = {
        //     id: id,
        //     ts: "summary",
        //     sum: sumOfGradSum,
        //   };
        //   row[orderNo] = data.consumptionSum;
        //   rows["all"].push(row);
        // }

        // if (rowIndex[selectedMonth] > -1) {
        //   rows[selectedMonth][rowIndex[selectedMonth]].sum = grandSum;
        //   rows[selectedMonth][rowIndex[selectedMonth]][orderNo] =
        //     sumOfMeterSum[selectedMonth][orderNo];
        // } else {
        //   const row = {
        //     id: id,
        //     ts: "summary",
        //     sum: grandSum,
        //   };
        //   row[orderNo] = data.consumptionSum;
        //   console.log(selectedMonth, data.consumptionSum, row);
        //   rows[selectedMonth].push(row);
        // }

        // data.data.map((hourlyData) => {
        const rowIndex = [];
        const id = md5(hourlyData.ts);
        rowIndex["all"] = rows["all"].findIndex((obj) => obj.id === id);
        rowIndex[selectedMonth] = rows[selectedMonth].findIndex(
          (obj) => obj.id === id,
        );
        if (rowIndex["all"] > -1) {
          rows["all"][rowIndex["all"]][orderNo] = hourlyData.consumptionV;
        } else {
          const row = {
            id: id,
            ts: hourlyData.ts,
          };
          row[orderNo] = hourlyData.consumptionV;
          rows["all"].push(row);
        }

        if (rowIndex[selectedMonth] > -1) {
          rows[selectedMonth][rowIndex[selectedMonth]][orderNo] =
            hourlyData.consumptionV;
        } else {
          const row = {
            id: id,
            ts: hourlyData.ts,
          };
          row[orderNo] = hourlyData.consumptionV;
          rows[selectedMonth].push(row);
        }
        return null;
      });
      return null;
      // });

      // objConsumptionData.details.hourlyAggregatedData.map(
      //   (objHourlyAggregatedData) => {
      //     const rowIndex = [];
      //     const id = md5(objHourlyAggregatedData.ts);
      //     rowIndex["all"] = rows["all"].findIndex((obj) => obj.id === id);
      //     rowIndex[selectedMonth] = rows[selectedMonth].findIndex(
      //       (obj) => obj.id === id,
      //     );
      //
      //     if (rowIndex["all"] > -1) {
      //       rows["all"][rowIndex["all"]].sum =
      //         objHourlyAggregatedData.consumptionV;
      //     } else {
      //       const row = {
      //         id: id,
      //         ts: objHourlyAggregatedData.ts,
      //         sum: objHourlyAggregatedData.consumptionV,
      //       };
      //       rows["all"].push(row);
      //     }
      //
      //     if (rowIndex[selectedMonth] > -1) {
      //       rows[selectedMonth][rowIndex[selectedMonth]].sum =
      //         objHourlyAggregatedData.consumptionV;
      //     } else {
      //       const row = {
      //         id: id,
      //         ts: objHourlyAggregatedData.ts,
      //         sum: objHourlyAggregatedData.consumptionV,
      //       };
      //       rows[selectedMonth].push(row);
      //     }
      //
      //     return null;
      //   },
      // );

      // row.id = md5(obj.)
      // return null;
    });

    // columns.push(dateColumn(gettext));
    // columns.push(numberColumn("sum", gettext("Sum"), locale));
    // const dataForColumns = data.consumptionEnergyData.consumptionData[0].details.data;
    // dataForColumns.map((obj) => {
    //   columns.push(numberColumn(obj.meter, obj.meter, locale));
    //   return null;
    // });
  }

  return rows;
};
