import { useTextDomainContext, useUser } from "../../providers";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { MONTHLY_STATEMENT_DATA } from "../../../hooks/energy/commands";
import PropTypes from "prop-types";
import {
  energyMonthlyRows,
  energyStatementKey,
  getMonthAbbreviation,
  getMonthNameFromNumber,
  indexedDbGetById,
  indexedDbUpdate,
  urlFriendlyString,
} from "../../../utils";
import { isEqual } from "lodash";
import { EnergyMonthlyStatement, EnergyYearStatement, FilterBar1 } from "../";

const InitialMonthFalse = {
  jan: false,
  feb: false,
  mar: false,
  apr: false,
  may: false,
  jun: false,
  jul: false,
  aug: false,
  sep: false,
  oct: false,
  nov: false,
  dec: false,
};
export const OverallStatement = (props) => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  // const locale = useLang();
  const { user } = useUser();

  let { currentTeam: customer, currentSubsidiary: group } = user || {};

  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  //#region React Hooks (useState)
  // const propsDefault = {
  //   statementType: "energy",
  // };

  const [energyType, setEnergyType] = useState("electricity");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("all");
  // const [isClimate, setIsClimate] = useState(false);
  const [dataReceivedMonth, setDataReceivedMonth] = useState(InitialMonthFalse);
  const [dataReceivedYear, setDataReceivedYear] = useState(false);
  // const [rowCountYear, setRowCountYear] = useState(-1);
  // const [rowCountMonth, setRowCountMonth] = useState(InitialMonthRowCount);
  // const [excelData, setExcelData] = useState([]);
  // const [monthColumns, setMonthColumns] = useState([]);
  // const [monthRowsData, setMonthRowsData] = useState([]);
  // const [yearRowsData, setYearRowsData] = useState([]);
  const [loading, setLoading] = useState(InitialMonthFalse);
  const [loadingYear, setLoadingYear] = useState(false);
  const [loaded, setLoaded] = useState(-1);
  const [infoText, setInfoText] = useState("");
  // const [statementType, setStatementType] = useState(
  //   props.statementType || propsDefault.statementType,
  // );
  const [infoTextObject, setInfoTextObject] = useState({});

  const [fileNameForExport, setFileNameForExport] =
    useState("energy-statement");
  // const [filtersYear, setFiltersYear] = useState({
  //   meterType: energyType,
  //   customer: customer,
  //   group: group,
  //   year: year,
  //   isClimate: isClimate,
  // });
  //#endregion  React Hooks (useState)

  //#region React Hooks (useMemo)

  const statementType = useMemo(() => {
    return props.statementType || "energy";
  }, [props.statementType]);

  const isClimate = useMemo(() => {
    return statementType === "climate";
  }, [statementType]);

  const filtersYear = useMemo(() => {
    setLoaded(-1);
    setInfoTextObject({});
    return {
      meterType: energyType,
      customer: customer,
      group: group,
      year: year,
      isClimate: isClimate,
    };
  }, [customer, energyType, group, isClimate, year]);
  //#endregion React Hooks (useMemo)

  //#region Data
  const [getData] = useLazyQuery(MONTHLY_STATEMENT_DATA);
  // const [getYearData] = useLazyQuery(CONSUMPTION_ENERGY_DATA_AGGREGATED_DATA);
  // const [getMonthData] = useLazyQuery(CONSUMPTION_ENERGY_DATA_MONTH_DATA);

  // const varYearColumns = useMemo(() => {
  //   return yearColumns(gettext, locale);
  //   // return [
  //   //   {
  //   //     field: "meter",
  //   //     headerName: gettext("Order No"),
  //   //     width: 200,
  //   //   },
  //   //   {
  //   //     field: "meterNumber",
  //   //     headerName: gettext("Meter No"),
  //   //     width: 150,
  //   //   },
  //   //   {
  //   //     field: "customer",
  //   //     headerName: gettext("Company"),
  //   //     width: 250,
  //   //   },
  //   //   // {
  //   //   //   field: "address",
  //   //   //   headerName: gettext("Address"),
  //   //   //   width: 300,
  //   //   // },
  //   //   {
  //   //     field: "streetName",
  //   //     headerName: gettext("Street"),
  //   //     width: 200,
  //   //   },
  //   //   {
  //   //     field: "buildingNumber",
  //   //     headerName: gettext("House No."),
  //   //     width: 100,
  //   //   },
  //   //   {
  //   //     field: "postcode",
  //   //     headerName: gettext("Zip Code"),
  //   //     width: 100,
  //   //   },
  //   //   {
  //   //     field: "cityName",
  //   //     headerName: gettext("City"),
  //   //     width: 150,
  //   //   },
  //   //   {
  //   //     field: "consumerCVR",
  //   //     headerName: gettext("Company No."),
  //   //     width: 100,
  //   //   },
  //   //   {
  //   //     field: "total",
  //   //     headerName: gettext("Sum"),
  //   //     // flex: 1,
  //   //     width: 150,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "jan",
  //   //     headerName: gettext("January"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "feb",
  //   //     headerName: gettext("February"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "mar",
  //   //     headerName: gettext("March"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "apr",
  //   //     headerName: gettext("April"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "may",
  //   //     headerName: gettext("May"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "jun",
  //   //     headerName: gettext("June"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "jul",
  //   //     headerName: gettext("July"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "aug",
  //   //     headerName: gettext("August"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "sep",
  //   //     headerName: gettext("September"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "oct",
  //   //     headerName: gettext("October"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "nov",
  //   //     headerName: gettext("November"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   //   {
  //   //     field: "dec",
  //   //     headerName: gettext("December"),
  //   //     // flex: 1,
  //   //     width: 100,
  //   //     type: "number",
  //   //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   //   },
  //   // ];
  // }, [gettext, locale]);

  const excelData = useMemo(() => {
    const customerName = group.toLowerCase() === "all" ? customer : group;
    return [
      {
        filter: filtersYear,
        // title: gettext("Overall statement"),
        // columns: varYearColumns,
        // rows: yearRowsData,
        customer: {
          name: `${customerName} - ${year}`,
          logo: {
            file: "",
            extension: "png",
            width: 100,
            height: 50,
            link: "https://app.fabeke.com",
          },
        },
        partner: {
          name: "Energi Team ApS",
          logo: {
            file: "/media/partners/energiteam/logos/logo-energy-team-one-line.png",
            extension: "png",
            width: 393,
            height: 62,
            link: "https://app.energiteam.dk",
          },
        },
      },
      // {
      //   title: gettext("January"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jan || [],
      // },
      // {
      //   title: gettext("February"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.feb || [],
      // },
      // {
      //   title: gettext("March"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.mar || [],
      // },
      // {
      //   title: gettext("April"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.apr || [],
      // },
      // {
      //   title: gettext("May"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.may || [],
      // },
      // {
      //   title: gettext("June"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jun || [],
      // },
      // {
      //   title: gettext("July"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jul || [],
      // },
      // {
      //   title: gettext("August"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.aug || [],
      // },
      // {
      //   title: gettext("September"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.sep || [],
      // },
      // {
      //   title: gettext("October"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.oct || [],
      // },
      // {
      //   title: gettext("November"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.nov || [],
      // },
      // {
      //   title: gettext("December"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.dec || [],
      // },
    ];
  }, [customer, group, year, filtersYear]);
  //#endregion Data

  //#region React Hooks (useEffect)

  // useEffect(() => {
  //   if (typeof props.statementType !== "undefined") {
  //     setStatementType(props.statementType);
  //   }
  // }, [props.statementType]);

  // useEffect(() => {
  //   if (statementType === "climate") {
  //     setIsClimate(true);
  //   } else {
  //     setIsClimate(false);
  //   }
  // }, [statementType]);

  useEffect(() => {
    // setFiltersYear({
    //   meterType: energyType,
    //   customer: customer,
    //   group: group,
    //   year: year,
    //   isClimate: isClimate,
    // });
    // setLoaded(-1);
    setLoading(InitialMonthFalse);
    // setRowCountMonth(InitialMonthRowCount);
    setDataReceivedMonth(InitialMonthFalse);
    setDataReceivedYear(false);
    // setRowCountYear(-1);
    // setInfoTextObject({});
    setInfoText("");
  }, []);

  useEffect(() => {
    setLoadingYear(true);
    const keyYear = energyStatementKey("aggregated", filtersYear);
    indexedDbGetById("cache", keyYear).then((dataIndexedDb) => {
      if (dataIndexedDb?.length) {
        setDataReceivedYear(true);
        setLoaded(0);
        setLoadingYear(false);
      } else {
        const newInfoTextObject = infoTextObject;
        newInfoTextObject.year = gettext("Year Data: Downloading");
        setInfoText(Object.values(newInfoTextObject).join("\n"));
        if (!isEqual(infoTextObject, newInfoTextObject)) {
          setInfoTextObject(newInfoTextObject);
        }

        setDataReceivedYear(false);
        getData({
          variables: {
            ...filtersYear,
            month: "info",
          },
        }).then(({ data }) => {
          const newInfoTextObject = infoTextObject;
          newInfoTextObject.year = gettext("Year Data: Processing");
          setInfoText(Object.values(newInfoTextObject).join("\n"));
          if (!isEqual(infoTextObject, newInfoTextObject)) {
            setInfoTextObject(newInfoTextObject);
          }

          const dataForSaving = data?.monthlyStatementData?.metersInfo;

          indexedDbUpdate("cache", dataForSaving, keyYear).then(() => {
            setDataReceivedYear(true);
            setLoaded(0);
            setLoadingYear(false);
            const newInfoTextObject = infoTextObject;
            delete newInfoTextObject.year;
            setInfoText(Object.values(newInfoTextObject).join("\n"));
            if (!isEqual(infoTextObject, newInfoTextObject)) {
              setInfoTextObject(newInfoTextObject);
            }
            data = null;
          });
        });
      }
    });
  }, [filtersYear, getData, gettext, infoTextObject]);

  useEffect(() => {
    if (loaded === 0) {
      const keyMonthData4Columns = energyStatementKey(
        "monthData4Columns",
        filtersYear,
      );

      indexedDbGetById("cache", keyMonthData4Columns).then((dataIndexedDb) => {
        if (!dataIndexedDb?.monthlyStatementData) {
          const newInfoTextObject = infoTextObject;
          newInfoTextObject.monthColumns = gettext(
            "Month Columns: Downloading",
          );
          setInfoText(Object.values(newInfoTextObject).join("\n"));
          if (!isEqual(infoTextObject, newInfoTextObject)) {
            setInfoTextObject(newInfoTextObject);
          }

          getData({
            variables: {
              ...filtersYear,
              month: "info",
            },
          }).then(({ data }) => {
            const newInfoTextObject = infoTextObject;
            newInfoTextObject.monthColumns = gettext(
              "Month Columns: Processing",
            );
            setInfoText(Object.values(newInfoTextObject).join("\n"));
            if (!isEqual(infoTextObject, newInfoTextObject)) {
              setInfoTextObject(newInfoTextObject);
            }

            indexedDbUpdate("cache", data, keyMonthData4Columns).then(() => {
              const newInfoTextObject = infoTextObject;
              delete newInfoTextObject.monthColumns;
              setInfoText(Object.values(newInfoTextObject).join("\n"));
              if (!isEqual(infoTextObject, newInfoTextObject)) {
                setInfoTextObject(newInfoTextObject);
              }
              data = null;
            });
          });
        }
      });
    }
  }, [filtersYear, getData, gettext, infoTextObject, loaded]);

  useEffect(() => {
    if (loaded > -1 && loaded < 12) {
      const monthNumber = loaded + 1;
      const monthName = getMonthAbbreviation(monthNumber);
      const keyMonth = energyStatementKey(monthName, filtersYear);
      const keyBenchmark = energyStatementKey("benchmark", filtersYear);

      // console.log(monthNumber, monthName);

      indexedDbGetById("cache", keyMonth).then((dataIndexedDb) => {
        const newDataReceivedMonth = dataReceivedMonth;
        let receivedMonthValue = true;
        receivedMonthValue = !!dataIndexedDb?.length;
        newDataReceivedMonth[monthName] = receivedMonthValue;

        if (dataIndexedDb?.length) {
          setLoaded(loaded + 1);
        } else {
          const newInfoTextObject = infoTextObject;
          newInfoTextObject[monthName] =
            gettext("Month Data: Downloading") +
            " (" +
            getMonthNameFromNumber(loaded + 1) +
            ")";
          setInfoText(Object.values(newInfoTextObject).join("\n"));
          if (!isEqual(infoTextObject, newInfoTextObject)) {
            setInfoTextObject(newInfoTextObject);
          }

          const newLoading = loading;
          newLoading[monthName] = true;

          getData({
            variables: {
              ...filtersYear,
              month: loaded + 1,
            },
          }).then(({ data }) => {
            const newInfoTextObject = infoTextObject;
            newInfoTextObject[monthName] =
              gettext("Month Data: Processing") +
              " (" +
              getMonthNameFromNumber(loaded + 1) +
              ")";
            setInfoText(Object.values(newInfoTextObject).join("\n"));
            if (!isEqual(infoTextObject, newInfoTextObject)) {
              setInfoTextObject(newInfoTextObject);
            }

            // console.log(
            //   data?.monthlyStatementData?.month,
            //   data?.monthlyStatementData?.benchmark,
            // );

            if (data?.monthlyStatementData?.month) {
              indexedDbGetById("cache", keyBenchmark).then((dataBenchmark) => {
                dataBenchmark = dataBenchmark || {};
                dataBenchmark[data.monthlyStatementData.month] =
                  data?.monthlyStatementData?.benchmark;
                indexedDbUpdate("cache", dataBenchmark, keyBenchmark).then();
              });
            }

            const allRowsData = energyMonthlyRows(data, gettext);

            const newDataReceivedMonth = dataReceivedMonth;
            indexedDbUpdate("cache", allRowsData[monthName], keyMonth).then(
              () => {
                newDataReceivedMonth[monthName] = true;
                setDataReceivedMonth(newDataReceivedMonth);
                setLoaded(loaded + 1);
                newLoading[monthName] = false;

                const newInfoTextObject = infoTextObject;
                delete newInfoTextObject[monthName];
                setInfoText(Object.values(newInfoTextObject).join("\n"));
                if (!isEqual(infoTextObject, newInfoTextObject)) {
                  setInfoTextObject(newInfoTextObject);
                }

                if (!isEqual(loading, newLoading)) {
                  setLoading(newLoading);
                }
                data = null;
              },
            );
          });
        }

        //
      });
    }
  }, [
    dataReceivedMonth,
    filtersYear,
    getData,
    gettext,
    infoTextObject,
    loaded,
    loading,
  ]);

  // useEffect(() => {
  //   if (filtersYear?.meterType) {
  //     const key = energyStatementKey("aggregated", filtersYear);
  //     indexedDbGetById("cache", key).then((result) => {
  //       setYearRowsData(result);
  //     }).catch();
  //   }
  // }, [filtersYear, loaded]);

  // useEffect(() => {
  //   const key = energyStatementKey("monthData4Columns", filtersYear);
  //   if (filtersYear?.customer) {
  //     indexedDbGetById("cache", key).then((result) => {
  //       const columnsData = energyMonthlyColumns(result, gettext, locale);
  //       setMonthColumns(columnsData);
  //     });
  //   }
  // }, [filtersYear, gettext, locale, loaded]);

  // useEffect(() => {
  //   for (let tmp = 1; tmp <= 12; tmp++) {
  //     const monthName = getMonthAbbreviation(tmp);
  //     const key = energyStatementKey(monthName, filtersYear);
  //     indexedDbGetById("cache", key).then((result) => {
  //       if (result?.length) {
  //         const newMonthRowsData = {
  //           ...monthRowsData
  //         };
  //         newMonthRowsData[monthName] = result;
  //         if (!isEqual(newMonthRowsData, monthRowsData)) {
  //           setMonthRowsData(newMonthRowsData);
  //         }
  //       }
  //     });
  //   }
  // }, [filtersYear, monthRowsData, loaded]);

  // useEffect(() => {
  //   const customerName = group.toLowerCase() === "all" ? customer : group;
  //   const excelDataArray = [
  //     {
  //       title: gettext("Overall statement"),
  //       columns: yearColumns,
  //       rows: yearRowsData,
  //       customer: {
  //         name: customerName + " - " + year,
  //         logo: {
  //           // file: "/media/partners/fabeke/logos/logo-dark.png",
  //           file: "",
  //           extension: "png",
  //           width: 100,
  //           height: 50,
  //           link: "https://app.fabeke.com",
  //         }
  //       },
  //       partner: {
  //         name: "Energi Team ApS",
  //         logo: {
  //           file: "/media/partners/energiteam/logos/logo-energy-team-one-line.png",
  //           extension: "png",
  //           width: 393,
  //           height: 62,
  //           link: "https://app.energiteam.dk",
  //         }
  //       },
  //     },
  //     {
  //       title: gettext("January"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.jan ? monthRowsData.jan : []
  //     },
  //     {
  //       title: gettext("February"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.feb ? monthRowsData.feb : []
  //     },
  //     {
  //       title: gettext("March"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.mar ? monthRowsData.mar : []
  //     },
  //     {
  //       title: gettext("April"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.apr ? monthRowsData.apr : []
  //     },
  //     {
  //       title: gettext("May"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.may ? monthRowsData.may : []
  //     },
  //     {
  //       title: gettext("June"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.jun ? monthRowsData.jun : []
  //     },
  //     {
  //       title: gettext("July"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.jul ? monthRowsData.jul : []
  //     },
  //     {
  //       title: gettext("August"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.aug ? monthRowsData.aug : []
  //     },
  //     {
  //       title: gettext("September"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.sep ? monthRowsData.sep : []
  //     },
  //     {
  //       title: gettext("October"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.oct ? monthRowsData.oct : []
  //     },
  //     {
  //       title: gettext("November"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.nov ? monthRowsData.nov : []
  //     },
  //     {
  //       title: gettext("December"),
  //       columns: monthColumns,
  //       rows: monthRowsData?.dec ? monthRowsData.dec : []
  //     },
  //   ];
  //
  //   setExcelData(excelDataArray);
  // }, [customer, gettext, group, monthColumns, monthRowsData, year, yearColumns, yearRowsData]);

  useEffect(() => {
    let filenamePrefix = "energy-statement-";
    if (isClimate) {
      filenamePrefix = "climate-statement-";
    }

    const customerName = group.toLowerCase() === "all" ? customer : group;

    setFileNameForExport(
      urlFriendlyString(
        filenamePrefix +
          customerName +
          "-" +
          energyType +
          "-" +
          year +
          "-" +
          month +
          "-" +
          Math.floor(Date.now() / 1000),
      ),
    );
  }, [energyType, year, month, customer, isClimate, group]);

  //#endregion React Hooks (useEffect)

  //#region Handlers
  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.selectedYearDate) {
      setYear(selectedValues.selectedYearDate.getFullYear());
    }

    if (selectedValues?.selectedMonth?.value) {
      setMonth(selectedValues.selectedMonth.value);
    }

    if (selectedValues?.consumptionOrCo2?.value) {
      // switch (selectedValues?.consumptionOrCo2?.value) {
      //   case "climate":
      //   case "co2":
      //     setIsClimate(true);
      //     break;
      //   default:
      //     setIsClimate(false);
      //     break;
      // }
    }

    //
    // if (selectedValues?.building?.value) {
    //   setBuilding(selectedValues.building.value);
    // }
    //
    // if (selectedValues?.meter?.value) {
    //   setMeter(selectedValues.meter.value);
    // }

    // if (selectedValues?.selectedYearDate) {
    //   setSelectedYearDate(selectedValues.selectedYearDate);
    // }
  };
  //#endregion Handlers

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        consumptionOrCo2={isClimate ? "climate" : "consumption"}
        showEnergyType
        // showConsumptionOrCo2
        showYear
        showMonth
        // showMonth={selectedEnergyStatement === "monthly-statement"}
      />
      <br />

      {month === "all" && (
        <EnergyYearStatement
          // onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          // data={data}
          // columns={varYearColumns}
          // rows={yearRowsData || []}
          excelData={excelData}
          fileNameForExport={fileNameForExport}
          loading={loadingYear || !dataReceivedYear}
          isClimate={isClimate}
          // rowCount={rowCountYear}
          infoText={infoText}
        />
      )}

      {month !== "all" && (
        <EnergyMonthlyStatement
          // onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          month={month}
          // data={data}
          // columns={monthColumns}
          // rows={monthRowsData[month] || []}
          excelData={excelData}
          fileNameForExport={fileNameForExport}
          loading={loading[month] || !dataReceivedMonth[month]}
          isClimate={isClimate}
          // rowCount={rowCountMonth[month]}
          infoText={infoText}
        />
      )}

      {/*<BarChartBox*/}
      {/*  loading={loading}*/}
      {/*  title={gettext("Consumption measured in kWh")}*/}
      {/*  chartId={"consumption"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [{*/}
      {/*      dataKey: "consumptionV",*/}
      {/*      label: gettext("Consumption"),*/}
      {/*      color: theme.palette.chart.electricity,*/}
      {/*      unit: gettext("kWh"),*/}
      {/*    }]*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<br/>*/}

      {/*<TableView1*/}
      {/*  title={gettext("Electricity consumption")}*/}
      {/*  rows={metersData}*/}
      {/*  // rows={[]}*/}
      {/*  columns={columns}*/}
      {/*  loading={loading}*/}
      {/*  checkboxSelection={false}*/}
      {/*  initialState={initialState}*/}
      {/*  showGridToolbarDeleteButton={false}*/}
      {/*  showGridToolbarDensitySelector={false}*/}
      {/*  showGridToolbarFilterButton={false}*/}
      {/*  showGridToolbarQuickFilter={true}*/}
      {/*  showGridToolbarColumnsButton={false}*/}
      {/*  fileNameForExport={fileNameForExport}*/}
      {/*/>*/}
    </>
  );
};

OverallStatement.propTypes = {
  statementType: PropTypes.string,
};
