import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { AcquireLogin, ProtectedRoute } from "./components";
import {
  AcceptSocialLogin,
  ActiveUserList,
  AddDeal,
  AllUsers,
  Carbon,
  ChangePassword,
  ClimateStatement,
  CompanyUsers,
  ConfirmEmailRedirectFlow,
  DealsList,
  DeveloperGenerateEmail,
  DeveloperModals,
  DeveloperModules,
  DeveloperRoles,
  DeveloperUsers,
  DeveloperVersion,
  EnergyConsumption,
  EnergyConsumptionFuture,
  EnergyStatement,
  ExcelProcessor,
  ForgotPassword,
  Invoice,
  Login,
  Logout,
  MyPage,
  Notification,
  Page1,
  PageNotFound,
  PartnerUsers,
  PersonaInformation,
  Privacy,
  Procurement,
  RecoverPassword,
  RedirectFlow,
  Register,
  ResetPassword,
  SplashScreen,
  SystemAllUserCustomers,
  SystemRoles,
  SystemUsers,
  TariffStatement,
  Terms,
  Translation,
  VerificationEmailSent,
} from "../pages";
import { AppLayout, AuthLayout, HeaderLayout } from "../layouts";
import Datahub from "../pages/support/Datahub";
import { ModulesList } from "../components";
import { EnergyStatementCache } from "../pages/Energy/EnergyStatementCache"; // import {AccountSettings,

// import {AccountSettings,
//   ChangePassword,
//   Customers,
//   DataHubRedirectFlow,
//   ErrorPage1,
//   ErrorPage2,
//   ErrorPage3,
//   ErrorPage4,
//   ErrorPage5,
//   ErrorPage6,
//   ForgotPassword,
//   Logout,
//   PersonaInformation,
//   PublicFacingEnergyPricesPage,
//   PublicFacingPage,
//   RecoverPassword,
//   RedirectFlow,
//   UserSettings} from "app/modules";
// import {AddMeterData,
//   BuildingMeters,
//   CustomerBuildings,
//   PartnerMemberspage,
//   UnAuthorized,
//   UserCustomer,
//   UserRoleUpdate} from "app/elements";
// import { ResetPassword } from "app/modules/Auth/ResetPassword";
// import { Token } from "../pages/Login/Token";
// import { AddMeterForm } from "app/features";
// import Privacy from "../../app/modules/Guest/pages/Privacy";
// import Terms from "../../app/modules/Guest/pages/Terms";

/**
 *
 */
export default function AppRoutes() {
  return (
    <>
      <Routes>
        {/* region Redirect Routes */}
        {/*<Route path="/" element={<Navigate to="/dashboard" />} />*/}
        {/*<Route path="/" element={<Navigate to="/energy"/>}/>*/}
        <Route path="/auth" element={<Navigate to="/auth/login" />} />
        <Route path="/register/flow" element={<Navigate to="/dashboard" />} />
        <Route
          path="/user-profile"
          element={<Navigate to="/user-profile/personal-information" />}
        />
        {/* endregion Redirect Routes */}

        {/* region Guest Pages */}
        <Route element={<HeaderLayout />}>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          {/* <Route */}
          {/*   path="/public/budget/prices/electricity" */}
          {/*   element={<PublicFacingEnergyPricesPage />} */}
          {/* /> */}
        </Route>

        <Route path="/r/fb/:customerKey/:email" element={<RedirectFlow />} />
        <Route
          path="/r/:type/fb/:customerKey/:email"
          element={<RedirectFlow />}
        />
        {/* <Route */}
        {/*   path="/public/:customerId/dashboard/:dashboardKey" */}
        {/*   element={<PublicFacingPage />} */}
        {/* /> */}

        {/* <Route path="/partneraccess/:token" element={<PartnerAccess />} /> */}
        <Route path="/social/login" element={<AcceptSocialLogin />} />
        <Route path="/confirm_email/" element={<ConfirmEmailRedirectFlow />} />
        <Route path="/error/error-v1" element={<PageNotFound />} />
        <Route path="/splash" element={<SplashScreen />} />
        {/* <Route path="/error/error-v2" element={<ErrorPage2 />} /> */}
        {/* <Route path="/error/error-v3" element={<ErrorPage3 />} /> */}
        {/* <Route path="/error/error-v4" element={<ErrorPage4 />} /> */}
        {/* <Route path="/error/error-v5" element={<ErrorPage5 />} /> */}
        {/* <Route path="/error/error-v6" element={<ErrorPage6 />} /> */}
        {/* endregion Guest Pages */}

        {/* region On Login Layout */}
        {/* Routes outside of Authentication */}
        <Route element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />} />
          {/* <Route path="/auth/token" element={<Token />} /> */}
          <Route path="/reset_password/" element={<ResetPassword />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset_password/:code" element={<RecoverPassword />} />
        </Route>
        {/* endregion On Login Layout */}

        {/* region Authenticated Pages */}

        <Route element={<AcquireLogin />}>
          <Route path="/" element={<Navigate to="/energy" />} />

          {/* region Without Layout */}
          <Route path="/logout" element={<Logout />} />
          {/* <Route path="/data-hub/redirect" element={<DataHubRedirectFlow />} /> */}
          {/* <Route path="/registration-flow" element={<RegistrationFlow />} /> */}
          <Route
            path="/verification-email"
            element={<VerificationEmailSent />}
          />
          {/* endregion Without Layout */}

          {/* region With App Layout */}
          <Route element={<AppLayout />}>
            {/* region Sustainability */}

            <Route
              path="/energy"
              element={
                <ProtectedRoute
                  element={<EnergyConsumption />}
                  codes={[
                    "consumption",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/developer/energy"
              element={
                <ProtectedRoute
                  element={<EnergyConsumptionFuture />}
                  codes={["under-development"]}
                />
              }
            />

            <Route
              path="/developer/invoice"
              element={
                <ProtectedRoute
                  element={<Invoice />}
                  codes={["under-development"]}
                />
              }
            />

            <Route
              path="/developer/modals"
              element={
                <ProtectedRoute
                  element={<DeveloperModals />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/qadeer/page1"
              element={
                <ProtectedRoute element={<Page1 />} codes={["developer"]} />
              }
            />

            {/* endregion Sustainability */}

            {/* region Sustainability */}

            <Route
              path="/climate"
              element={
                <ProtectedRoute
                  element={<Carbon />}
                  codes={[
                    "sustainability",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* endregion Sustainability */}

            {/* region Procurement */}

            <Route
              path="/procurement"
              element={
                <ProtectedRoute
                  element={<Procurement />}
                  codes={[
                    "procurement",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* endregion Procurement */}

            {/* region Energy Statement */}
            <Route
              path="/statement/energy"
              element={
                <ProtectedRoute
                  element={<EnergyStatement />}
                  codes={[
                    "energy-statement",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/statement/climate"
              element={
                <ProtectedRoute
                  element={<ClimateStatement />}
                  codes={[
                    "energy-statement",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/statement/tariff"
              element={
                <ProtectedRoute
                  element={<TariffStatement />}
                  codes={[
                    "energy-statement",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/statement/cache"
              element={
                <ProtectedRoute
                  element={<EnergyStatementCache />}
                  codes={[
                    "energy-statement",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* endregion Energy Statement */}

            {/* region Energy Contracts */}

            <Route
              path="/data/deals"
              element={
                <ProtectedRoute
                  element={<DealsList />}
                  codes={[
                    "energy-contracts-full",
                    "energy-contracts-readonly",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/data/deals/add"
              element={
                <ProtectedRoute
                  element={<AddDeal />}
                  codes={[
                    "energy-contracts-full",
                    "energy-contracts-readonly",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/data/deals/edit/:id"
              element={
                <ProtectedRoute
                  element={<AddDeal />}
                  codes={[
                    "energy-contracts-full",
                    "energy-contracts-readonly",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* endregion Energy Contracts */}

            {/* region Data Quality */}

            {/*<Route*/}
            {/*  path="/data-quality/consumption"*/}
            {/*  element={<ProtectedRoute element={<DataQualityConsumption/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/* endregion Data Quality */}

            {/* region My Account */}

            <Route
              path="/notifications"
              element={
                <ProtectedRoute
                  element={<Notification />}
                  codes={["under-development"]}
                />
              }
            />

            {/* <Route */}
            {/*   path="/account/settings" */}
            {/*   element={<ProtectedRoute element={<AccountSettings />} codes={["under-development"]} />} */}
            {/* /> */}

            <Route
              path="/user-profile/personal-information"
              element={
                <ProtectedRoute
                  element={<PersonaInformation />}
                  codes={[
                    "allow-to-all",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* <Route */}
            {/*   path="/user-profile/settings" */}
            {/*   element={<ProtectedRoute element={<UserSettings />} codes={["under-development"]} />} */}
            {/* /> */}

            <Route
              path="/user-profile/change-password"
              element={
                <ProtectedRoute
                  element={<ChangePassword />}
                  codes={["under-development"]}
                />
              }
            />

            <Route
              path="/user-profile/customers"
              element={
                <ProtectedRoute
                  element={<CompanyUsers />}
                  codes={["under-development"]}
                />
              }
            />

            {/* endregion My Account */}

            {/* region System Admin */}

            <Route
              path="/system-admin/all-users"
              element={
                <ProtectedRoute
                  element={<AllUsers />}
                  codes={[
                    "all-users-readonly",
                    "all-users-full",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            <Route
              path="/system-admin/system-users"
              element={
                <ProtectedRoute
                  element={<SystemUsers />}
                  codes={[
                    "system-users-readonly",
                    "system-users-full",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />

            {/* <Route */}
            {/*   path="/system-admin/default-settings" */}
            {/*   element={<ProtectedRoute element={<AdminDefaultSettings />} codes={["under-development"]} />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/service-providers" */}
            {/*   element={<ProtectedRoute element={<AdminServiceProviders />} codes={["under-development"]} />} */}
            {/* /> */}

            {/*<Route*/}
            {/*  path="/system-admin/users/:uid/update-role"*/}
            {/*  element={<ProtectedRoute element={<UserRoleUpdate/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            <Route
              path="/system-admin/roles"
              element={
                <ProtectedRoute
                  element={<SystemRoles />}
                  codes={["everything-full", "everything-readonly"]}
                />
              }
            />

            <Route
              path="/system-admin/roles/permissions/:key"
              element={
                <ProtectedRoute
                  element={<ModulesList />}
                  codes={["everything-full", "everything-readonly"]}
                />
              }
            />

            {/*<Route*/}
            {/*  path="/system-admin/users/:uid/customers"*/}
            {/*  element={<ProtectedRoute element={<UserCustomer/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            <Route
              path="/system-admin/all-users/:uid/customers"
              element={
                <ProtectedRoute
                  element={<SystemAllUserCustomers />}
                  codes={["under-development"]}
                />
              }
            />

            {/*<Route*/}
            {/*  path="/system-admin/users/:uid/customers/:cid/buildings"*/}
            {/*  element={<ProtectedRoute element={<CustomerBuildings/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/system-admin/users/:uid/customers/:cid/buildings/:bid/meters/:type"*/}
            {/*  element={<ProtectedRoute element={<BuildingMeters prevUrl={window.location.pathname}/>}*/}
            {/*    codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/* <Route */}
            {/*   path="/system-admin/users/:uid/customers/:cid/buildings/:bid/meters/:type/add" */}
            {/*   element={<ProtectedRoute element={<AddMeterForm />} codes={["under-development"]} />} */}
            {/* /> */}

            {/*<Route*/}
            {/*  path="/system-admin/users/:uid/customers/:cid/buildings/:bid/meters/:type/:meterNo/add-data"*/}
            {/*  element={<ProtectedRoute element={<AddMeterData/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/* <Route */}
            {/*   path="/system-admin/partners-list" */}
            {/*   element={<ProtectedRoute element={<PartnersList />} codes={["under-development"]} />} */}
            {/* /> */}

            {/*<Route*/}
            {/*  path="/system-admin/partners-list/:pkey/members"*/}
            {/*  element={<ProtectedRoute element={<PartnerMemberspage/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/system-admin/partners-list/:pkey/members/:cid/buildings"*/}
            {/*  element={<ProtectedRoute element={<CustomerBuildings/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/system-admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type"*/}
            {/*  element={<ProtectedRoute element={<BuildingMeters prevUrl={window.location.pathname}/>}*/}
            {/*    codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/* <Route */}
            {/*   path="/system-admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type/add" */}
            {/*   element={<ProtectedRoute element={<AddMeterForm />} codes={["under-development"]} />} */}
            {/* /> */}

            {/*<Route*/}
            {/*  path="/system-admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type/:meterNo/add-data"*/}
            {/*  element={<ProtectedRoute element={<AddMeterData/>} codes={["under-development"]}/>}*/}
            {/*/>*/}

            {/* <Route */}
            {/*   path="/system-admin/scrapper-log" */}
            {/*   element={<ProtectedRoute element={<ScrapperLog />} codes={["under-development"]} />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/admin-energy-supplier" */}
            {/*   element={<ProtectedRoute element={<AdminEnergySupplier />} codes={["under-development"]} />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/scrapper-list" */}
            {/*   element={<ProtectedRoute element={<ScraperList />} codes={["under-development"]} />} */}
            {/* /> */}

            {/* endregion System Admin */}

            {/* region Partner */}
            <Route
              path="/partner-admin/partner-users"
              element={
                <ProtectedRoute
                  element={<PartnerUsers />}
                  codes={[
                    "partner-users-readonly",
                    "partner-users-full",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />
            {/* endregion Partner */}

            {/* region Company */}
            <Route
              path="/company-admin/company-users"
              element={
                <ProtectedRoute
                  element={<CompanyUsers />}
                  codes={[
                    "company-users-readonly",
                    "company-users-full",
                    "everything-full",
                    "everything-readonly",
                  ]}
                />
              }
            />
            {/* endregion Partner */}

            {/* region Dev Admin */}

            <Route
              path="/developer/users"
              element={
                <ProtectedRoute
                  element={<DeveloperUsers />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/roles"
              element={
                <ProtectedRoute
                  element={<DeveloperRoles />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/permissions"
              element={
                <ProtectedRoute
                  element={<DeveloperModules />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/version"
              element={
                <ProtectedRoute
                  element={<DeveloperVersion />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/generate-email"
              element={
                <ProtectedRoute
                  element={<DeveloperGenerateEmail />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/excel"
              element={
                <ProtectedRoute
                  element={<ExcelProcessor />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/developer/aggregation"
              element={
                <ProtectedRoute
                  element={<EnergyStatement />}
                  codes={["developer"]}
                />
              }
            />

            {/*<Route element={<RequirePermission codes={["developer"]}/>}>*/}
            {/*  <Route path="/developer/users" element={<DeveloperUsers/>}/>*/}
            {/*</Route>*/}

            {/*<Route element={<RequirePermission codes={["developer"]}/>}>*/}
            {/*  <Route path="/developer/roles" element={<DeveloperRoles/>}/>*/}
            {/*</Route>*/}

            {/*<Route element={<RequirePermission codes={["developer"]}/>}>*/}
            {/*  <Route path="/developer/permissions" element={<DeveloperModules/>}/>*/}
            {/*</Route>*/}

            {/*<Route element={<RequirePermission codes={["developer"]}/>}>*/}
            {/*  <Route path="/developer/version" element={<DeveloperVersion/>}/>*/}
            {/*</Route>*/}
            {/* endregion Dev Admin */}

            {/* region System Support */}
            <Route
              path="/support/datahub"
              element={
                <ProtectedRoute element={<Datahub />} codes={["developer"]} />
              }
            />

            <Route
              path="/support/translation"
              element={
                <ProtectedRoute
                  element={<Translation />}
                  codes={["developer"]}
                />
              }
            />

            <Route
              path="/support/active-users"
              element={
                <ProtectedRoute
                  element={<ActiveUserList />}
                  codes={["developer"]}
                />
              }
            />

            {/*<Route element={<RequirePermission codes={["support-datahub"]}/>}>*/}
            {/*  <Route path="/support/datahub" element={<Datahub/>}/>*/}
            {/*</Route>*/}

            {/*<Route element={<RequirePermission codes={["support-translation"]}/>}>*/}
            {/*  <Route path="/support/translation" element={<Translation/>}/>*/}
            {/*</Route>*/}

            {/*<Route element={<RequirePermission codes={["support-active-users"]}/>}>*/}
            {/*  <Route path="/support/active-users" element={<ActiveUserList/>}/>*/}
            {/*</Route>*/}
            {/* endregion System Support */}

            {/* region Error Pages */}
            {/*<Route path="/unauthorized" element={<UnAuthorized/>}/>*/}
            <Route path="/error/error-v3" element={<PageNotFound />} />
            <Route path="/my-page" element={<MyPage />} />
            {/* endregion Error Pages */}

            {/* region Not used Pages */}
            {/* <Route element={<RequirePermission codes={["dashboard"]} />}> */}
            {/* <Route path="/dashboard-old" element={<Dashboard />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/budget" element={<Prices />}> */}
            {/*   <Route */}
            {/*     path=":energyType/prices/:country/:date" */}
            {/*     element={<EnergyPrices />} */}
            {/*   /> */}
            {/*   <Route */}
            {/*     path=":energyType/prices/:country" */}
            {/*     element={<EnergyPrices />} */}
            {/*   /> */}
            {/* </Route> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-energy"]} />}> */}
            {/*   <Route path="/buildings/energy" element={<Energy />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-heating"]} />}> */}
            {/*   <Route path="/buildings/heating" element={<Heat />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-water"]} />}> */}
            {/*   <Route path="/buildings/water" element={<Water />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-gas"]} />}> */}
            {/*   <Route path="/buildings/gas" element={<Gas />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-epc"]} />}> */}
            {/*   <Route path="/buildings/epc" element={<Epc />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-co2-report"]} />}> */}
            {/*   <Route path="/buildings/co2-report" element={<NewCo2Report />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["building-benchmark"]} />}> */}
            {/*   <Route path="/buildings/benchmark" element={<Benchmark />} /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["building-administration"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     path="/buildings/administration" */}
            {/*     element={<UserCustomersPage />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["building-administration"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     path="/buildings/administration/:cid/buildings" */}
            {/*     element={ */}
            {/*       <Administration prevUrl={window.location.pathname} /> */}
            {/*     } */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["building-administration"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     // path="/buildings/administration/:bid/meters/:type" */}
            {/*     // path="/buildings/administration/customers/:cid/buildings/:bid/meters/:type" */}
            {/*     path="/buildings/administration/:cid/buildings/:bid/meters/:type" */}
            {/*     element={ */}
            {/*       <BuildingMeters prevUrl={window.location.pathname} /> */}
            {/*     } */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["building-administration"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     // path="/buildings/administration/:bid/meters/:type/add" */}
            {/*     path="/buildings/administration/:cid/buildings/:bid/meters/:type/add" */}
            {/*     element={<AddMeterForm />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["building-administration"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     // path="/buildings/administration/:bid/meters/:type/:meterNo/add-data" */}
            {/*     path="/buildings/administration/:cid/buildings/:bid/meters/:type/:meterNo/add-data" */}
            {/*     element={<AddMeterData />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-report"]} />}> */}
            {/*   <Route path="/company/ghg-result" element={<GhgResult />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-data"]} />}> */}
            {/*   <Route path="/company/data" element={<AddAccountsData />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-data"]} />}> */}
            {/*   <Route */}
            {/*     path="/company/data/:id/view" */}
            {/*     element={<AccountFileDataList />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-account-link"]} />}> */}
            {/*   <Route path="/company/account-link" element={<AccountLink />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-account-link"]} />}> */}
            {/*   <Route */}
            {/*     path="/company/account-link/:planId/account-link-view" */}
            {/*     element={<ViewAccountPlan />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-account-link"]} />}> */}
            {/*   <Route */}
            {/*     path="/company/account-link/:planId/account-link-edit" */}
            {/*     element={<AddEditPlan />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-account-link"]} />}> */}
            {/*   <Route */}
            {/*     path="/company/account-link/account-link-edit" */}
            {/*     element={<AddEditPlan />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["company-account-link"]} />}> */}
            {/*   <Route path="/company/data-link" element={<DataLink />} /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list" */}
            {/*     element={<PartnerMembersList />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list/:cid/buildings" */}
            {/*     element={<CustomerBuildings />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list/:cid/buildings" */}
            {/*     element={<CustomerBuildings />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list/:cid/buildings/:bid/meters/:type" */}
            {/*     element={<BuildingMeters prevUrl={window.location.pathname} />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list/:cid/buildings/:bid/meters/:type/add" */}
            {/*     // path="/buildings/administration/:cid/buildings/:bid/meters/:type/add" */}
            {/*     element={<AddMeterForm />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/partner/members-list/:cid/buildings/:bid/meters/:type/:meterNo/add-data" */}
            {/*     element={<AddMeterData />} */}
            {/*   /> */}
            {/* </Route> */}
            {/* <Route element={<RequirePermission codes={["prediction"]} />}> */}
            {/*   <Route */}
            {/*     path="/prediction/electricity" */}
            {/*     element={<PredictionElectricity />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route path="/report/new" element={<NewReport />} /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/energy-processes" */}
            {/*   element={<EnergyProcesses />} */}
            {/* /> */}

            {/* <Route path="/corporate-co2/purchase" element={<Purchase />} /> */}

            {/* <Route path="/corporate-co2/transport" element={<Transport />} /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/waste-recycling" */}
            {/*   element={<WasteRecycling />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/sold-products" */}
            {/*   element={<SoldProducts />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/transactions" */}
            {/*   element={<Transactions />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/transactions-edit" */}
            {/*   element={<AddTransactions />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/corporate-co2/climate-report" */}
            {/*   element={<ClimateReport />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/energy-consumption/energy-comparison" */}
            {/*   element={<EnergyComparison />} */}
            {/* /> */}

            {/* {canRead("map") && ( */}
            {/* <Route element={<RequirePermission codes={["map"]} />}> */}
            {/*   <Route path="/report/map" element={<MapBox />} code="map" /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["partner-users"]} />}> */}
            {/*   <Route */}
            {/*     path="/user-profile/partner-web" */}
            {/*     element={<AccessFromPartnerWebsite />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route */}
            {/*   element={<RequirePermission codes={["my-account-customer-roles"]} />} */}
            {/* > */}
            {/*   <Route */}
            {/*     path="/user-profile/customer-roles" */}
            {/*     element={<CustomerRoles />} */}
            {/*   /> */}
            {/* </Route> */}

            {/* <Route element={<RequirePermission codes={["my-account-api-key"]} />}> */}
            {/*   <Route path="/user-profile/api-key" element={<APIKeyPage />} /> */}
            {/* </Route> */}

            {/* <Route path="/user-profile/api-docs" element={<APIDocsPage />} /> */}

            {/* <Route path="/system-admin/emission-groups" element={<EmissionGroups />} /> */}

            {/* <Route */}
            {/*   path="/system-admin/emission-categories" */}
            {/*   element={<EmissionGroups />} */}
            {/* /> */}

            {/* <Route path="/system-admin/account-groups" element={<AccountGroupList />} /> */}

            {/* <Route */}
            {/*   path="/system-admin/account-groups/:id/edit" */}
            {/*   element={<AccountGroupEdit />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/account-groups/new" */}
            {/*   element={<AccountGroupCreate />} */}
            {/* /> */}

            {/* <Route path="/system-admin/account-charts" element={<AccountChartList />} /> */}

            {/* <Route */}
            {/*   path="/system-admin/account-charts/:id/edit" */}
            {/*   element={<AccountChartEdit />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/account-charts/new" */}
            {/*   element={<AccountChartCreate />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/emission-factors" */}
            {/*   element={<EmissionFactorsList />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/emission-factors/:id/edit" */}
            {/*   element={<EmissionFactorsEdit />} */}
            {/* /> */}

            {/* <Route */}
            {/*   path="/system-admin/emission-factors/new" */}
            {/*   element={<EmissionFactorsCreate />} */}
            {/* /> */}

            {/* )} */}
            {/* endregion Not used Pages */}
          </Route>
          {/* endregion With App Layout */}
        </Route>

        {/* endregion Authenticated Pages */}

        {/* region Page Not Found */}
        <Route
          path="*"
          element={<Navigate to="/error/error-v1" replace={true} />}
        />
        {/* endregion Page Not Found */}
      </Routes>
    </>
  );
}
