import React, {useEffect, useState} from "react";
import {Button, Grid, Menu, Typography, useTheme} from "@mui/material";
import {IoCalendar, IoChevronDown} from "react-icons/io5";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

/**
 *
 * @param props
 */
export const YearPicker = (props) => {
  const { getSelectedYearDate } = props;
  // const {TextDomainContext} = useTextDomainContext();
  // const {gettext} = useContext(TextDomainContext);
  const theme = useTheme();
  const localStorageKey = "YearPicker";
  let defaultSelectedYearDate = new Date();
  defaultSelectedYearDate.setDate(1);
  defaultSelectedYearDate.setMonth(defaultSelectedYearDate.getMonth() - 1);

  const cacheValues = JSON.parse(localStorage.getItem(localStorageKey));

  if (cacheValues?.selectedYearDate) {
    defaultSelectedYearDate = new Date(cacheValues.selectedYearDate);
  } else if (typeof props.selectedDate !== "undefined") {
    defaultSelectedYearDate = new Date(props.selectedYearDate);
  }

  const defaultOptions = {
    selectedYearDate: defaultSelectedYearDate,
  };

  // const [startDateValue, setStartDateValue] = useState(new Date());
  // const [endDateValue, setEndDateValue] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedYearDate, setSelectedYearDate] = useState(
    defaultOptions.selectedYearDate,
  );

  useEffect(() => {
    if (typeof getSelectedYearDate === "function") {
      getSelectedYearDate(selectedYearDate);
    }
    handleSegmentClose();
  }, [getSelectedYearDate, selectedYearDate]);

  // const dateValueChange = event => {
  //   const [start, end] = event;
  //   setStartDateValue(start);
  //   setEndDateValue(end);
  //   if (start && end && props.onValueChange) {
  //     props.onValueChange(event);
  //   }
  // };

  // const selectedOptions = {
  //   ...defaultOptions, ...props
  // };

  // useEffect(() => {
  //   if (selectedOptions.defaultStartDate) {
  //     setStartDateValue(selectedOptions.defaultStartDate);
  //   }
  // }, [selectedOptions.defaultStartDate]);

  // useEffect(() => {
  //   if (selectedOptions.defaultEndDate) {
  //     setEndDateValue(selectedOptions.defaultEndDate);
  //   }
  // }, [selectedOptions.defaultEndDate]);

  const handleSegmentClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateChange = (event) => {
    setSelectedYearDate(event);
    const item = {
      selectedYearDate: event,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(item));
    handleSegmentClose();
    // console.log(event);
    // const [start, end] = event;
    // setStartDate(start);
    // setEndDate(end);
    // if (start && end) {
    //   const item = {
    //     startDate: start,
    //     endDate: end,
    //     segmentIndex,
    //     selectedSegment
    //   };
    //
    //   localStorage.setItem(localStorageKey, JSON.stringify(item));
    //
    //   handleSegmentClose();
    // }
  };

  const handleSegmentClose = () => {
    setAnchorEl(null);
  };

  // const formattedDate = () => {
  //   let dateStart = new Date();
  //   let dateEnd = new Date();
  //   if (startDate) {
  //     dateStart = startDate;
  //   }
  //
  //   if (endDate) {
  //     dateEnd = endDate;
  //   }
  //
  //   const dayStart = dateStart.getDate();
  //   const monthStart = dateStart.toLocaleString("default", {
  //     month: "short"
  //   });
  //   const yearStart = dateStart.getFullYear();
  //
  //   const dayEnd = dateEnd.getDate();
  //   const monthEnd = dateEnd.toLocaleString("default", {
  //     month: "short"
  //   });
  //   const yearEnd = dateEnd.getFullYear();
  //
  //   if (selectedSegment === "year") {
  //     return `${yearStart} - ${yearEnd}`;
  //   } else if (selectedSegment === "month") {
  //     return `${monthStart} ${yearStart} - ${monthEnd} ${yearEnd}`;
  //   } else {
  //     return `${dayStart} ${monthStart} ${yearStart} - ${dayEnd} ${monthEnd} ${yearEnd}`;
  //   }
  // };

  return (
    <>
      {props.view === "table" ? (
        <Button
          endIcon={<IoChevronDown size={12} />}
          size="small"
          onClick={handleSegmentClick}
          sx={{
            width: "160px",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid #E4E4E7",
            },
          }}
        >
          <Typography fontSize="14px" fontWeight="500" color="secondary.800">
            Calender
          </Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          // color="secondary"
          style={{
            color: theme.palette.grey[600],
            height: "32px",
          }}
          endIcon={<IoCalendar />}
          onClick={handleSegmentClick}
        >
          <Typography fontSize="14px" fontWeight="fontWeightMedium">
            {selectedYearDate.getFullYear()}
            {/*{formattedDate()}*/}
          </Typography>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSegmentClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            height: "100%",
          },
          "& .MuiMenu-paper": {
            width: "220px",
            borderRadius: "8px",
            boxShadow:
              "0px 10px 15px -3px #0000001A ,0px 4px 6px -2px #0000000F",
            overflow: "hidden",
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "secondary.900",
            fontWeight: 600,
            fontSize: "14px",
            color: "white",
          },
        }}
      >
        {/*<Grid container columnSpacing={1} xs={12}>*/}
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <DatePicker
              className="my-datepicker"
              selected={selectedYearDate}
              // startDate={startDate}
              // endDate={endDate}
              onChange={handleDateChange}
              dateFormat="yyyy"
              showMonthDropdown={false}
              showYearPicker
              dropdownMode="select"
              // selectsRange
              inline
              // minDate={
              //     futureDates === false &&
              //     new Date().setMonth(new Date().getMonth())
              // }
              maxDate={
                props.futureDates !== false
                  ? new Date()
                  : new Date().setMonth(new Date().getMonth() + 3)
              }
              // maxDate={futureDates !== false && new Date()}
              // dayClassName={date => {
              //   return date.getMonth() !== startDate.getMonth() ? "disabled" : "";
              // }}
              showMonthYearDropdown={false}
            ></DatePicker>
          </Grid>
        </Grid>
      </Menu>

      {/*<DatePicker*/}
      {/*  customInput={<ButtonInput {...props} />}*/}
      {/*  selectsRange*/}
      {/*  onChange={dateValueChange}*/}
      {/*  {...selectedOptions}*/}
      {/*>*/}
      {/*  <Typography fontSize="14px" color={"secondary.900"}>{selectedOptions.note}</Typography>*/}
      {/*</DatePicker>*/}
    </>
  );
};

YearPicker.propTypes = {
  futureDates: PropTypes.bool,
  view: PropTypes.string,
  selectedDate: PropTypes.object,
  selectedYearDate: PropTypes.object,
  getSelectedYearDate: PropTypes.func,
};
