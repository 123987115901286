export const getStartAndEndDate = (selectedDate, selectedSegment) => {
  let startDate, endDate;

  const date = new Date(selectedDate); // Ensure selectedDate is a Date object
  // const year = date.getUTCFullYear();
  // const month = date.getUTCMonth();
  // const day = date.getUTCDate();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  switch (selectedSegment) {
    case "year":
      // startDate = new Date(Date.UTC(year, 0, 1)); // January 1st of the year (UTC)
      // endDate = new Date(Date.UTC(year + 1, 0, 1) - 1); // January 1st of the next year (UTC) minus 1ms
      startDate = new Date(year, 0, 1); // January 1st of the year (UTC)
      endDate = new Date(year + 1, 0, 1) - 1; // January 1st of the next year (UTC) minus 1ms
      break;
    case "month":
      // startDate = new Date(Date.UTC(year, month, 1)); // 1st day of the month (UTC)
      // endDate = new Date(Date.UTC(year, month + 1, 1) - 1); // 1st day of the next month (UTC) minus 1ms
      startDate = new Date(year, month, 1); // 1st day of the month (UTC)
      endDate = new Date(year, month + 1, 1) - 1; // 1st day of the next month (UTC) minus 1ms
      break;
    case "day":
      // startDate = new Date(Date.UTC(year, month, day)); // Start of the day (UTC)
      // endDate = new Date(Date.UTC(year, month, day + 1) - 1); // Start of the next day (UTC) minus 1ms
      startDate = new Date(year, month, day); // Start of the day (UTC)
      endDate = new Date(year, month, day + 1) - 1; // Start of the next day (UTC) minus 1ms
      break;
    default:
      throw new Error(
        'Invalid selectedSegment, must be "year", "month", or "day"',
      );
  }

  return { startDate, endDate };
};
