import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import styled from "@mui/styled-engine";
import Zoom from "@mui/material/Zoom";
import {
  Button,
  Chip,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { IoChevronDown } from "react-icons/io5";
import { getTableCells, useGetHeadCells } from "../hooks";
import NewDatePicker from "./NewDatePicker";
import NoResults from "./NoResults";
import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import { TableLoadingState } from "../../../../components";
import PropTypes from "prop-types";

// import { styled } from "@mui/system";

const CustomContainer = styled(Box)(() => ({
  padding: 12,
  paddingTop: 10,
  border: "1px solid #E4E4E7",
  borderRadius: "12px",
  height: "100%",
  width: "100%",
  alignItems: "center",
}));

const CustomFooter = styled(Box)(() => ({
  marginTop: 25,
  paddingTop: 18,
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: "1px solid #E4E4E7",
}));

const CustomPagination = styled(Pagination)(() => ({
  ".MuiPaginationItem-root": {
    minHeight: "30px",
    margin: 0,
    width: "41px",
    fontSize: "14px",
    borderRadius: "0px",
    border: "1px solid #E4E4E7",
    color: "#71717A",
  },
  ".css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    fontSize: "20px",
  },
  ".MuiPaginationItem-previousNext": {
    maxWidth: "30px !important",
    // borderTopRightRadius: "8px",
    // borderBottomRightRadius: "8px",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{
      popper: className,
    }}
  >
    Test
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4D896F",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    fontWeight: 400,
    boxShadow:
      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    align: "center",
    padding: "8px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

/**
 * Descending Comparator
 * @param a
 * @param b
 * @param orderBy
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param order
 * @param orderBy
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * @param props
 */
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderSortIcon = (headCell) => {
    return (
      <>
        {headCell.label !== "" ? (
          <>
            <NorthRoundedIcon
              sx={{
                color:
                  order === "asc" && headCell.id === orderBy
                    ? "primary.800"
                    : "secondary.200",
                fontSize: "12px",
                mr: -0.8,
                ml: 0.5,
              }}
            />
            <SouthRoundedIcon
              sx={{
                color:
                  order === "desc" && headCell.id === orderBy
                    ? "primary.800"
                    : "secondary.200",
                fontSize: "12px",
                mt: 0.2,
              }}
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: "#fff",
              border: "none",
              borderBottom: "1px solid #E4E4E7",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            <LightTooltip
              title={headCell.tooltip}
              TransitionComponent={Zoom}
              placement="top-start"
              // textAlign="center"
              arrow
              enterDelay={700}
              leaveDelay={100}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => renderSortIcon(headCell)}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </LightTooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/**
 *
 * @param props
 */
function EnhancedTableToolbar(props) {
  const {
    title,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    filterBySearch,
    selectededDate,
    segment,
    getSelectedDate,
    onSegmentChange,
    gettext,
    view,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const rowOptions = [10, 25, 50, 100, 200, 500];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (item) => {
    setRowsPerPage(item);
    setPage(0);
    setAnchorEl(null);
  };

  return (
    <Toolbar
      sx={{
        px: "15px",
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h5"
        fontWeight="700"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>

      {view === "Meter Detail" ? (
        <NewDatePicker
          selectededDate={selectededDate}
          segment={segment}
          getSelectedDate={getSelectedDate}
          onSegmentChange={onSegmentChange}
          gettext={gettext}
          view="table"
        />
      ) : (
        <>
          <Button
            endIcon={<IoChevronDown size={12} />}
            size="small"
            aria-controls={open ? "select-company-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              width: "220px",
              "&:hover": {
                backgroundColor: "#fff",
                border: "1px solid #E4E4E7",
              },
            }}
          >
            <Typography fontSize="14px" fontWeight="500" color="secondary.800">
              {gettext("Show up to")}
            </Typography>
            <Chip
              sx={{
                ml: 0.5,
                fontSize: "14px",
                backgroundColor: "secondary.100",
                color: "primary.main",
              }}
              size="small"
              label={rowsPerPage}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                px: 1,
                borderRadius: "8px",
                width: "146px",
                border: "1px solid #E4E4E7",
                boxShadow:
                  "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {rowOptions.map((item, index) => (
              <MenuItem
                key={Math.random()}
                sx={{
                  borderRadius: "4px",
                  justifyContent: "center",
                }}
                onClick={() => handleChangeRowsPerPage(item)}
                value={rowOptions[index]}
                selected={rowsPerPage === rowOptions[index]}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "secondary.800",
                  }}
                >
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}

      <Stack direction={"row"} spacing={1} ml={1}>
        <OutlinedInput
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "14px",
            color: "secondary.800",
            borderRadius: "8px",
            height: "30px",
            width: "208px",
          }}
          type={"text"}
          size="small"
          placeholder="Search"
          onChange={filterBySearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="secondary.300" fontSize="medium" />
            </InputAdornment>
          }
        />
      </Stack>
    </Toolbar>
  );
}

/**
 *
 * @param props
 */
export default function DataHubTable(props) {
  const {
    title,
    setView,
    view,
    rows,
    setSelectedItem,
    selectedItem,
    selectededDate,
    segment,
    period,
    getSelectedDate,
    onSegmentChange,
    gettext,
    loading,
  } = props;

  const headCells = useGetHeadCells(view, segment);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customerName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    view === "Meter Detail" ? 100 : 10,
  );
  const [filteredRows, setFilteredRows] = React.useState(rows);

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const rowsShowing = () => {
    return rowsPerPage > filteredRows.length - page * rowsPerPage
      ? filteredRows.length - page * rowsPerPage
      : rowsPerPage;
  };

  const handleView = (row) => {
    if (view === "Customers") {
      setSelectedItem({
        ...selectedItem,
        ...row,
      });
      setView("Buildings");
    } else if (view === "Buildings") {
      setSelectedItem({
        ...selectedItem,
        ...row,
      });
      setView("Meters");
    } else if (view === "Meters") {
      setSelectedItem({
        ...selectedItem,
        ...row,
      });
      setView("Meter Detail");
    }
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const filterBySearch = (event) => {
    const query = event.target.value;
    const updatedList = [...rows];
    const NewUpdatedList = updatedList.filter((item) => {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().search(query.toLowerCase()) !== -1,
      );
    });
    setFilteredRows(NewUpdatedList);
  };

  return (
    <CustomContainer>
      <EnhancedTableToolbar
        title={title}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        filterBySearch={filterBySearch}
        selectededDate={selectededDate}
        segment={segment}
        getSelectedDate={getSelectedDate}
        onSegmentChange={onSegmentChange}
        gettext={gettext}
        view={view}
      />
      <TableContainer
        sx={{
          minHeight: "70vh",
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            minWidth: 750,
            overflow: "auto",
          }}
        >
          {filteredRows.length ? (
            <>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                headCells={headCells}
              />

              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .sort(getComparator(order, orderBy))
                  .map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        {getTableCells(view, row, handleView, segment, period)}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </>
          ) : loading === true ? (
            TableLoadingState()
          ) : (
            <NoResults gettext={gettext} />
          )}
        </Table>
      </TableContainer>
      {view !== "Meter Detail" ? (
        <CustomFooter>
          <Typography fontSize={14} color="secondary.500">
            {gettext(
              `Showing ${rowsShowing()} of ${filteredRows.length} results`,
            )}
          </Typography>

          <CustomPagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            size="medium"
            page={page + 1}
            variant="outlined"
            shape="rounded"
            onChange={(event, value) => handleChangePage(event, value)}
          />
        </CustomFooter>
      ) : null}
    </CustomContainer>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  filterBySearch: PropTypes.func.isRequired,
  selectededDate: PropTypes.object,
  segment: PropTypes.string,
  getSelectedDate: PropTypes.func,
  onSegmentChange: PropTypes.func,
  gettext: PropTypes.func,
  view: PropTypes.string,
};

DataHubTable.propTypes = {
  title: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  selectededDate: PropTypes.object,
  segment: PropTypes.string,
  period: PropTypes.string,
  getSelectedDate: PropTypes.func,
  onSegmentChange: PropTypes.func,
  gettext: PropTypes.func,
  loading: PropTypes.bool,
};
