import { formatDateBySegmentNoYear, formatDateYear } from "../";

export const toBenchmarkDataClimate = (
  shortSegment,
  data,
  gettext,
  addTotal = false,
) => {
  let returnData = [];
  let totals = {};

  // console.log(data);

  data.forEach((yearData) => {
    yearData.data.forEach((row) => {
      const date = formatDateBySegmentNoYear(row.ts, shortSegment);
      const year = formatDateYear(row.ts);

      // console.log(date, row.ts);

      // Find if there's already an entry for this date
      let existingEntry = returnData.find((entry) => entry.ts === date);

      if (existingEntry) {
        // If entry exists, add the year data to it
        existingEntry[`consumptionV_${year}`] = row.consumptionV;
        existingEntry[`conversionRate_${year}`] = row.conversionRate;
        existingEntry[`co2_${year}`] = row.co2;
      } else {
        // If entry does not exist, create a new one
        let rowData = {
          id: date,
          label: date,
          ts: date,
        };
        rowData[`consumptionV_${year}`] = row.consumptionV;
        rowData[`conversionRate_${year}`] = row.conversionRate;
        rowData[`co2_${year}`] = row.co2;
        returnData.push(rowData);
      }

      // Add to totals
      if (!totals[`consumptionV_${year}`]) {
        totals[`consumptionV_${year}`] = 0;
      }
      totals[`consumptionV_${year}`] += row.consumptionV;

      if (!totals[`conversionRate_${year}`]) {
        totals[`conversionRate_${year}`] = 0;
      }
      totals[`conversionRate_${year}`] += row.conversionRate;

      if (!totals[`co2_${year}`]) {
        totals[`co2_${year}`] = 0;
      }
      totals[`co2_${year}`] += row.co2;

      if (!totals["rows"]) {
        totals["rows"] = 0;
      }
      totals["rows"]++;
    });
  });

  // Create the "Total" row
  if (addTotal) {
    let totalRow = {
      id: "Total",
      label: "Total/Average",
      ts: gettext("Total/Average"),
    };

    for (const year in totals) {
      if (year.startsWith("conversionRate")) {
        totalRow[year] = totals[year] / totals["rows"];
      } else {
        totalRow[year] = totals[year];
      }
    }
    returnData.push(totalRow);
  }

  return returnData;
};
