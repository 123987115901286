import React, {useRef, useState} from "react";
import {useAuth} from "../../providers";
import {Navigate, Outlet} from "react-router";
import {AppBar, Box, Grid, Link, Toolbar} from "@mui/material";
import { styled } from "styled-components";
import {PartnerImagePath} from "../../../hooks";
import "./assets/css/index.css";
import {getPartnerKey} from "../../../utils";
import {LanguageSelector} from "../../components";

const LogoImage = styled("img")(() => ({
  "width": "auto",
  "maxHeight": "40px"
}));
/**
 *
 */
export const AuthLayout = () => {
  const {isAuthenticated} = useAuth();
  const appbarRef = useRef(null);
  const [logo, setLogo] = useState();
  const partnerKey = getPartnerKey();
  // const {user} = useUser();

  // LogoImagePath(partnerKey, "dark").then(imageUrl => {
  //   setLogo(imageUrl);
  // });

  PartnerImagePath(partnerKey, "logos/logo-dark.png").then(imageUrl => {
    setLogo(imageUrl);
  });

  // const navigate = useNavigate();

  // const navigateToPricesPage = function() {
  //   navigate("/public/budget/prices/electricity");
  // };

  // return !isAuthenticated && !user ? (

  return !isAuthenticated ? (
    <>
      <Box sx={{
        minHeight: "100%",
        overflow: "hidden"
      }}
      >
        {/* style={{ background: "#19384d" }} */}
        <AppBar
          ref={appbarRef}
          position="static"
          // color="transparent"
          style={{
            // background: "#fff"
          }}
        >
          <Toolbar>
            <Box
              sx={{
                maxHeight: "60px"
                // minWidth: "100px",
                // width: "150px",
                // paddingY: 2
              }}
            >
              <Link href="/" sx={{
                lineHeight: "90px"
              }}>
                {logo && <LogoImage alt="logo" src={logo}/>}
              </Link>
            </Box>
            <Box sx={{
              flexGrow: 1
            }}/>
            <Box>
              <LanguageSelector/>
            </Box>
          </Toolbar>
        </AppBar>

        <Grid container>
          <Outlet/>
        </Grid>
        <footer></footer>
      </Box>
    </>
  ) : (
    <Navigate to="/"/>
  );
};

// AuthLayout.propTypes = {
//   children: PropTypes.node.isRequired
// };
