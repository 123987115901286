import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useAuth, useUser } from "../../providers";
import { Navigate, useLocation } from "react-router";
import { useApolloClient } from "@apollo/client";
import { indexedDbDeleteAll, indexedDbDeleteById } from "../../../utils";

export const Logout = () => {
  const location = useLocation();
  const { state } = location;
  const { user, setUser } = useUser();
  const { unsetToken } = useAuth();
  const client = useApolloClient();
  const storageLocale = localStorage.getItem("languageSelect");

  const [logoutCompleted, setLogoutCompleted] = useState(false);

  localStorage.clear();
  storageLocale && localStorage.setItem("languageSelect", storageLocale);
  indexedDbDeleteById("cache", "apollo-cache-persist").then(() => {
    indexedDbDeleteAll(["cache"]).then(() => {
      client.clearStore().then(() => {
        unsetToken();
        setUser(null);
      });
    });
  });

  useEffect(() => {
    /**
     *
     */
    async function handleLogout() {
      if (!state || !state?.loginAs) {
        setUser(null);
        unsetToken();
      }

      ReactGA.event({
        category: "Authentication",
        action: "Logout",
        label: user.email?.replace("@", "[at]"),
      });

      await indexedDbDeleteAll(["cache"]);
      await client.clearStore();
      unsetToken();
      setUser(null);
      setLogoutCompleted(true); // Mark the process as complete
    }

    handleLogout().then();
  }, [setUser, state, unsetToken, client, user]);

  if (!logoutCompleted) {
    return <div>Loading...</div>; // Show a loading state
  }

  if (state?.loginAs) {
    return <Navigate to="/energy" />;
  } else {
    return <Navigate to="/auth/login" />;
  }
};
