import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import checkIcon from "./assets/check.svg";
import errorIcon from "./assets/error.svg";
import infoIcon from "./assets/info.svg";
import warningIcon from "./assets/warning.svg";

import "./Toast.css";

export const Toast = (props) => {
  const autoDelete = true;
  const dismissTime = 7000;
  const position = "top-right";
  // const { toastList, position, autoDelete, dismissTime } = props;
  const { toastList } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    toastList.map((obj) => {
      switch (obj.type) {
        case "success":
          obj.icon = checkIcon;
          obj.backgroundColor = "#5cb85c";
          break;
        case "error":
          obj.icon = errorIcon;
          obj.backgroundColor = "#d9534f";
          break;
        case "info":
          obj.icon = infoIcon;
          obj.backgroundColor = "#5bc0de";
          break;
        case "warning":
          obj.icon = warningIcon;
          obj.backgroundColor = "#f0ad4e";
          break;
        default:
          obj.icon = checkIcon;
          obj.backgroundColor = "#5cb85c";
          break;
      }
      return obj;
    });

    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, dismissTime, list, deleteToast]);

  const deleteToast = useCallback(
    (id) => {
      const listItemIndex = list.findIndex((e) => e.id === id);
      const toastListItem = toastList.findIndex((e) => e.id === id);
      list.splice(listItemIndex, 1);
      toastList.splice(toastListItem, 1);
      setList([...list]);
    },
    [list, toastList],
  );

  // const deleteToast = id => {
  //   const listItemIndex = list.findIndex(e => e.id === id);
  //   const toastListItem = toastList.findIndex(e => e.id === id);
  //   list.splice(listItemIndex, 1);
  //   toastList.splice(toastListItem, 1);
  //   setList([...list]);
  // };

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`notification toast ${position}`}
            style={{
              backgroundColor: toast?.backgroundColor,
            }}
          >
            <button onClick={() => deleteToast(toast.id)}>X</button>
            <div className="notification-image">
              <img src={toast.icon} alt="" />
            </div>
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

// export default Toast;
