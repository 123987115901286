import React, {useContext, useEffect, useState} from "react";
import {FabekeDropdown} from "./FabekeDropdown";
import {useTextDomainContext, useUser} from "../../providers";
import PropTypes from "prop-types";
import {useCompanyBuildings} from "../../../hooks";
import {SimpleDropdown} from "./SimpleDropdown";
import {isEqual} from "lodash";

/**
 * Meter dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const MeterDropdownCommon = (props) => {
  const { user } = useUser();
  const {
    currentTeam: currentTeamDefault,
    currentSubsidiary: currentSubsidiaryDefault,
  } = user || {};
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);

  const propsDefault = {
    defaultValue: "all",
    buildingDefaultValue: "all",
    defaultLabel: gettext("All"),
    showAll: true,
    componentLabel: gettext("Installation number"),
    currentTeam: currentTeamDefault,
    currentSubsidiary: currentSubsidiaryDefault,
    selectedBuilding: "all",
    meters: [
      {
        label: gettext("All"),
        value: "all",
      },
    ],
  };

  //region UseState [React Hooks]
  const [buildingValue, setBuildingValue] = useState(
    props.buildingDefaultValue
      ? props.buildingDefaultValue
      : propsDefault.buildingDefaultValue,
  );
  const [meterValue, setMeterValue] = useState(
    props.defaultValue ? props.defaultValue : propsDefault.defaultValue,
  );
  const [showAll, setShowAll] = useState(
    props.showAll ? props.showAll : propsDefault.showAll,
  );
  const [componentLabel, setComponentLabel] = useState(
    props.componentLabel ? props.componentLabel : propsDefault.componentLabel,
  );
  const [currentTeam, setCurrentTeam] = useState(
    props.currentTeam ? props.currentTeam : propsDefault.currentTeam,
  );
  const [currentSubsidiary, setCurrentSubsidiary] = useState(
    props.currentSubsidiary
      ? props.currentSubsidiary
      : propsDefault.currentSubsidiary,
  );
  const [meters, setMeters] = useState(propsDefault.meters);
  const [selectedBuilding, setSelectedBuilding] = useState(
    props.building ? props.building : propsDefault.selectedBuilding,
  );
  //endregion UseState

  const { buildings: buildingData } = useCompanyBuildings(
    currentTeam,
    currentSubsidiary,
  );

  //region UseEffect [React Hooks]
  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      setMeterValue(propsDefault.defaultValue);
    } else {
      setMeterValue(props.defaultValue);
    }
  }, [props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.building === "undefined") {
      setSelectedBuilding(propsDefault.selectedBuilding);
    } else {
      setSelectedBuilding(props.building);
    }
  }, [props.building, props.showAll, propsDefault.selectedBuilding]);

  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    if (typeof props.currentTeam === "undefined") {
      setCurrentTeam(propsDefault.currentTeam);
    } else {
      setCurrentTeam(props.currentTeam);
    }
  }, [props.currentTeam, propsDefault.currentTeam]);

  useEffect(() => {
    if (typeof props.currentSubsidiary === "undefined") {
      setCurrentSubsidiary(propsDefault.currentSubsidiary);
    } else {
      setCurrentSubsidiary(props.currentSubsidiary);
    }
  }, [props.currentSubsidiary, propsDefault.currentSubsidiary]);

  useEffect(() => {
    if (props.energyType) {
      if (buildingData && buildingData.length > 0) {
        const buildingsArray = [];
        const metersArray = [];
        const meters2 = [];
        if (showAll) {
          metersArray.push({
            label: gettext("All"),
            value: "all",
          });
        }

        const firstBuildingValue = buildingData.find(
          (o) => o.value === buildingValue,
        );
        if (!firstBuildingValue) {
          // Building data changed
          if (showAll && buildingValue === propsDefault.buildingDefaultValue) {
            //
          } else if (showAll) {
            if (buildingValue !== propsDefault.buildingDefaultValue) {
              setBuildingValue(propsDefault.buildingDefaultValue);
            }
          } else if (buildingData[0].value) {
            if (buildingValue !== buildingData[0].value) {
              setBuildingValue(buildingData[0].value);
            }
          }
        }

        buildingData.map((building) => {
          buildingsArray.push(building);
          return true;
        });

        if (selectedBuilding === "all") {
          buildingsArray.map((building) => {
            if (building[props.energyType]) {
              meters2.push(building[props.energyType]);
            }
            return null;
          });
        } else if (selectedBuilding) {
          buildingsArray.map((building) => {
            if (
              building.value === selectedBuilding &&
              building[props.energyType]
            ) {
              meters2.push(building[props.energyType]);
            }
            return null;
          });
        }

        meters2.forEach((obj) => {
          if (obj) {
            obj.forEach((obj2) => {
              if (obj2.label && obj2.meterNo) {
                const meterObj = {
                  label: obj2.label,
                  value: obj2.meterNo,
                };
                metersArray.push(meterObj);
              }
            });
          }
        });

        const firstMeterValue = metersArray.find((o) => o.value === meterValue);
        if (!firstMeterValue) {
          // Building data changed
          if (showAll && meterValue === propsDefault.defaultValue) {
            //
          } else if (showAll) {
            if (meterValue !== propsDefault.defaultValue) {
              setMeterValue(propsDefault.defaultValue);
            }
          } else if (metersArray[0]?.value) {
            if (meterValue !== metersArray[0].value) {
              setMeterValue(metersArray[0].value);
            }
          }
        }

        if (!isEqual(meters, metersArray)) {
          setMeters(metersArray);
        }
        // setBuildings(buildingsArray);
      } else {
        if (showAll) {
          // The following lines cause infinite loop in use Effect when there is no data

          // setMeters([{
          //   label: gettext("All"),
          //   value: "all"
          // }]);
          if (meterValue !== propsDefault.defaultValue) {
            if (meterValue !== propsDefault.defaultValue) {
              setMeterValue(propsDefault.defaultValue);
            }
          }
        }
      }
    }
    // console.log("Loop check");
  }, [
    buildingData,
    buildingValue,
    gettext,
    meterValue,
    meters,
    props.energyType,
    propsDefault.buildingDefaultValue,
    propsDefault.defaultLabel,
    propsDefault.defaultValue,
    selectedBuilding,
    showAll,
  ]);
  //endregion useEffect

  //region Handlers
  const handleChange = (event) => {
    props.onChange(event);
    // if (typeof props.onChange === "function") {
    //   if (props.visualStyle === "simple") {
    //     props.onChange(event.target);
    //   } else if (props.visualStyle === "old") {
    //     props.onChange(event);
    //   } else {
    //     props.onChange(event);
    //   }
    // }
  };
  //endregion Handlers

  return (
    <>
      {props.visualStyle === "simple" && (
        <SimpleDropdown
          options={meters}
          componentLabel={componentLabel}
          defaultValue={meterValue}
          onChange={handleChange}
        />
      )}

      {props.visualStyle === "old" && (
        <FabekeDropdown
          options={meters}
          componentLabel={componentLabel}
          defaultValue={meterValue}
          onChange={handleChange}
        />
      )}
    </>
  );
};

MeterDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  building: PropTypes.string,
  energyType: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  visualStyle: PropTypes.oneOf(["simple", "old"]).isRequired,
  currentSubsidiary: PropTypes.string,
  buildingDefaultValue: PropTypes.string,
};
